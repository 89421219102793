import { getJwt } from '../jwt';
import { request } from './request';

export async function getAllNotifications() {
    const endpoint = 'notifications-get-all';
    return await request(getJwt(), endpoint, {});
}

export async function requestSupport(
    jwt: string,
    institutionsSNO: number,
    employeeRole: string,
    duration: string,
    agreementConfigurationsSNO: number
) {
    const endpoint = 'notifications-support-request-create';
    return await request(jwt, endpoint, { institutionsSNO, employeeRole, duration, agreementConfigurationsSNO });
}

export async function processRequest(jwt: string, notificationsSNO: number, action: string) {
    const endpoint = 'notifications-support-request-process';
    return await request(jwt, endpoint, { notificationsSNO, action });
}

export async function notifyUpdateShift(jwt: string, normWorkersSNO: number, date: string) {
    const endpoint = 'notifications-shift-change-create';
    return await request(jwt, endpoint, { normWorkersSNO, date });
}

export async function notificationSetCompleted(jwt: string, notificationsSNO: number) {
    const endpoint = 'notification-set-completed';
    return await request(jwt, endpoint, { notificationsSNO });
}
