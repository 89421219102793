import { Runtype } from 'runtypes/lib/runtype';
import { Runtype as SimpleRuntype, use } from 'simple-runtypes';
import log from '../logging/logging';
import { RuntypeError } from '../types/errors/runtype-error';
import { requestBase, RequestOptions } from './request-base';

export async function request<RequestParams = any>(
    jwt: string | undefined,
    path: string,
    requestParms: RequestParams,
    options?: RequestOptions
): Promise<any> {
    const response = await requestBase(jwt, path, requestParms, options);

    return response.payload;
}

export async function requestWithRuntype<ResponseType = any, RequestParms = any>(
    responseType: SimpleRuntype<ResponseType>,
    jwt: string | undefined,
    path: string,
    requestParms: RequestParms,
    options?: RequestOptions
): Promise<ResponseType> {
    const { requestId, payload } = await requestBase(jwt, path, requestParms, options);

    const validation = use(responseType, payload);

    if (validation.ok) {
        return validation.result;
    }

    log.error(
        JSON.stringify({
            validation,
            payload,
        }),
        'error:runtypes',
        `requestId:${requestId}`
    );

    throw validation.error;
}

export async function requestWithType<ResponseType = any, RequestParms = any>(
    responseType: Runtype<ResponseType>,
    jwt: string | undefined,
    path: string,
    requestParms: RequestParms,
    options?: RequestOptions
): Promise<ResponseType> {
    const { requestId, payload } = await requestBase(jwt, path, requestParms, options);

    const validation = responseType.validate(payload);

    if (validation.success) {
        return validation.value;
    }

    log.error(
        JSON.stringify({
            validation,
            payload,
        }),
        'error:runtypes',
        `requestId:${requestId}`
    );

    throw new RuntypeError(responseType, validation, payload, `'${path}' - Incompatible response payload`);
}
