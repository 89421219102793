import cx from 'classnames';
import { addSeconds } from 'date-fns';
import { CSSProperties, FC } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { notificationSetCompleted } from '../../api/notifications';
import { NotificationTimeRequestResultData } from '../../api/notifications.type';
import { useAppContext } from '../../app-context';
import { formatI18nMessage, formatI18nMessageInPlainText } from '../../components/format-i18n';
import { formatToDate, formatToTime } from '../../util/datetime';
import styles from '../notifications.module.css';
import { ItemProps } from './type';

const NotificationTimeRequestResult: FC<ItemProps<NotificationTimeRequestResultData>> = ({
    notificationsSNO,
    data,
    beforeRequestHandled,
    afterRequestHandled,
}) => {
    const { t } = useTranslation();
    const state = useAppContext();

    const handleOk = async () => {
        beforeRequestHandled?.(notificationsSNO);
        await notificationSetCompleted(state.jwt, notificationsSNO);
        afterRequestHandled?.(notificationsSNO);
    };

    const { translateAction, translateResult } = useTranslateAction();

    return (
        <Card className={styles.item}>
            <Card.Body>
                <div className="d-flex flex-wrap">
                    <div>
                        <h4>
                            {formatI18nMessageInPlainText(t('notification.time-request-result.title'), {
                                result: t(`notification.time-request-result.actioned.${data.result}`),
                            })}
                        </h4>
                        <div className={cx(styles['item-text'], 'mb-3')}>
                            {formatI18nMessage(t('notification.time-request-result.body'), {
                                action: <b>{translateAction(data.askedFor)}</b>,
                                result: <b style={styleByResult(data.result)}>{translateResult(data.result)}</b>,
                            })}
                            <div>
                                {data.result === 'deny' && data.description && (
                                    <>
                                        {t('notification.time-request-result.reason')}: <i>{data.description}</i>
                                    </>
                                )}
                            </div>
                        </div>
                        <Shift data={data} />
                    </div>
                    <div className="ms-auto mt-auto">
                        <Button variant="success" size="lg" onClick={handleOk}>
                            {t('notification.time-request.ok')}
                        </Button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default NotificationTimeRequestResult;

const useTranslateAction = () => {
    const { t } = useTranslation();

    return {
        translateAction: (action: NotificationTimeRequestResultData['askedFor']) =>
            t(`notification.time-request-result.type.${action}`),
        translateResult: (result: NotificationTimeRequestResultData['result']) =>
            t(`notification.time-request-result.actioned.${result}`),
    };
};

const styleByResult = (result: NotificationTimeRequestResultData['result']): CSSProperties => {
    if (result === 'accept') {
        return {
            color: 'var(--bs-success)',
        };
    } else {
        return {
            color: 'var(--bs-danger)',
        };
    }
};

const Shift: FC<{ data: NotificationTimeRequestResultData }> = ({ data }) => {
    const { t } = useTranslation();

    const { timeStart, timeEnd } = (() => {
        switch (data.askedFor) {
            case 'shift-new':
            case 'shift-update':
                return {
                    timeStart: data.shiftDst.timeStart,
                    timeEnd: addSeconds(data.shiftDst.timeEnd, 1),
                };
            case 'shift-delete':
                return {
                    timeStart: data.shiftSrc.timeStart,
                    timeEnd: addSeconds(data.shiftSrc.timeEnd, 1),
                };
        }
    })();

    const date = formatToDate(timeStart);
    const start = formatToTime(timeStart);
    const end = formatToTime(timeEnd);

    return (
        <Table>
            <thead>
                <tr>
                    <th>{t('notification.time-request-result.date')}</th>
                    <th>{t('notification.time-request-result.start')}</th>
                    <th>{t('notification.time-request-result.end')}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{date}</td>
                    <td>{start}</td>
                    <td>{end}</td>
                </tr>
            </tbody>
        </Table>
    );
};
