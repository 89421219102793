export function getFirstDayOfWeek(date: Date): Date {
    const dayOfWeek = date.getDay();

    // For the date system, sunday = 0 for getDay() and saturday = 6
    // we adjust for this so we correctly  get monday as the first
    // day of the week
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);

    const firstDayOfWeek = new Date(date);
    firstDayOfWeek.setDate(diff);
    return firstDayOfWeek;
}
