import * as st from 'simple-runtypes';
import { DateRuntype } from '../types/validations/date-runtype';

const NormTimeNotification = st.sloppyRecord({
    normTimeNotificationsSNO: st.number(),
    normWorkersSNO: st.number(),
    normDatesSNO: st.number(),
    type: st.literal('lowerLevelReservationBeatbyHigherLevel'),
    data: st.sloppyRecord({
        label: st.string(),
    }),
    value: st.number(),
    isHandled: st.number(),
    date: DateRuntype,
});

export type NormTimeNotification = ReturnType<typeof NormTimeNotification>;

export const NormTimeNotifications = st.array(NormTimeNotification);
