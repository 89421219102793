import { FC, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { logError } from '../error-handling';
import { RuntypeError } from '../types/errors/runtype-error';
import RuntypeDetailedError from './runtype-detailed-error';

const PageError: FC<FallbackProps> = ({ error }) => {
    const { t } = useTranslation();

    useEffect(() => {
        logError(error);
    }, [error]);

    return (
        <div className="p-5">
            <Alert variant="danger" className="p-3 flex-wrap">
                <Alert.Heading>
                    <h1>{t('technical-error')}</h1>
                </Alert.Heading>

                <Button onClick={() => window.location.reload()} variant="outline-danger" className="ms-auto">
                    {t('technical-error.reload')}
                </Button>

                {error instanceof RuntypeError && <RuntypeDetailedError error={error} />}
            </Alert>
        </div>
    );
};

export default PageError;
