import { parseIsoDateTime } from '../util/datetime';
import { request } from './request';

export async function getAllPeriods(jwt: string): Promise<Period[]> {
    const resp = (await request(jwt, 'norm-periods-get-all', {})) as Period[];
    return resp?.map((period) => ({
        ...period,
        start: parseIsoDateTime(period.timeStart),
        end: parseIsoDateTime(period.timeEnd),
    }));
}

export async function getPeriod(jwt: string, normPeriodsSNO: number): Promise<Period> {
    const period = (await request(jwt, 'norm-periods-get', {
        normPeriodsSNO,
    })) as Period;
    return {
        ...period,
        start: parseIsoDateTime(period.timeStart),
        end: parseIsoDateTime(period.timeEnd),
    };
}

export async function createPeriod(jwt: string, period: NewPeriod | NewPeriodFromExisting) {
    return await request(jwt, 'norm-period-create', period);
}
export async function clonePeriod(jwt: string, normPeriodsSNOSrc: number, yearStart: number, monthStart: string) {
    const params = { normPeriodsSNOSrc, yearStart, monthStart };
    return await request(jwt, 'norm-period-clone', params);
}

export async function setPeriodIsLocked(jwt: string, period: Period, isLocked: boolean) {
    const { normPeriodsSNO } = period;
    const params = { normPeriodsSNO, isLocked: isLocked ? 1 : 0 };
    await request(jwt, 'norm-periods-set-is-locked', params);
}

export async function setPeriodIsDraft(jwt: string, period: Period, isDraft: boolean) {
    const { normPeriodsSNO } = period;
    const params = { normPeriodsSNO, isDraft: isDraft ? 1 : 0 };
    await request(jwt, 'norm-periods-set-is-draft', params);
}

// This call deletes ALL relations
// It includes normTime which is very sensitive
// Jakob wants this functionality for now
//
// At a later stage, we will create at gentle delete method
// that will not delete if normTime is populated
// This coming method will still delete normWorkers, normTeams etc
// as this is trivial to recreate for the institution
// if they accidentally delete a norm period
export async function deletePeriodHard(jwt: string, period: Period) {
    const { normPeriodsSNO } = period;
    const params = { normPeriodsSNO };
    await request(jwt, 'norm-periods-delete-hard', params);
}

export async function fetchPeriodEmployees(jwt: string, period: Period): Promise<NormWorker[]> {
    const { normPeriodsSNO } = period;
    const params = { normPeriodsSNO };
    const result = await request(jwt, 'norm-workers-get-all', params);
    return result.normWorkers;
}

export async function addEmployeeToPeriod(
    jwt: string,
    period: Period,
    employeesSNO: number,
    normTeamsSNOPrimary: number,
    workhoursPerWeek: number,
    workhoursPercentage: number,
    isVisibleInWorkplan: number,
    dateStart: string,
    dateEnd: string
): Promise<void> {
    const { normPeriodsSNO } = period;
    const params = {
        normPeriodsSNO,
        employeesSNO,
        ...(normTeamsSNOPrimary === -1 ? {} : { normTeamsSNOPrimary }),
        workhoursPerWeek,
        workhoursPercentage,
        isVisibleInWorkplan,
        dateStart,
        dateEnd,
    };
    await request(jwt, 'norm-worker-create', params);
}

export async function addSubstituteToPeriod(
    jwt: string,
    period: Period,
    employeesSNO: number,
    normTeamsSNOPrimary: number,
    isVisibleInWorkplan: number,
    dateStart: string,
    dateEnd: string
) {
    const endpoint = 'norm-worker-create-substitute';
    const { normPeriodsSNO } = period;
    const params = {
        normPeriodsSNO,
        employeesSNO,
        ...(normTeamsSNOPrimary === -1 ? {} : { normTeamsSNOPrimary }),
        isVisibleInWorkplan,
        dateStart,
        dateEnd,
    };
    await request(jwt, endpoint, params);
}

export async function removeEmployee(jwt: string, normWorkersSNO: number) {
    const params = { normWorkersSNO };
    await request(jwt, 'norm-worker-delete', params);
}

export async function setEmployeeRole(jwt: string, employeesInNormPeriodsSNO: number, employeeRole: string) {
    const params = { employeesInNormPeriodsSNO, employeeRole };
    await request(jwt, 'norm-worker-set-role-in-norm-period', params);
}

export async function setEmployeeWorkhours(jwt: string, normWorkersSNO: number, workhoursPerWeek: number) {
    const params = { normWorkersSNO, workhoursPerWeek };
    await request(jwt, 'norm-worker-set-workhours-per-week', params);
}

export async function setEmployeeIsVisible(jwt: string, normWorkersSNO: number, isVisible: number) {
    const params = { normWorkersSNO, isVisible };
    await request(jwt, 'norm-worker-set-is-visible-in-workplan', params);
}

export async function setEmployeeStartDate(jwt: string, normWorkersSNO: number, dateStart: string) {
    const params = { normWorkersSNO, dateStart };
    await request(jwt, 'norm-worker-set-date-start', params);
}

export async function setEmployeeEndDate(jwt: string, normWorkersSNO: number, dateEnd: string) {
    const params = { normWorkersSNO, dateEnd };
    await request(jwt, 'norm-worker-set-date-end', params);
}

export async function setEmployeeVacationDays(jwt: string, normWorkersSNO: number, vacationDays: number) {
    const params = { normWorkersSNO, vacationDays };
    await request(jwt, 'norm-worker-set-vacation-days', params);
}

export async function setEmployeeVacationSixthWeekDays(
    jwt: string,
    normWorkersSNO: number,
    vacationSixthWeekDays: number
) {
    const params = { normWorkersSNO, vacationSixthWeekDays };
    await request(jwt, 'norm-worker-set-vacation-sixth-week-days', params);
}

export async function setEmployeeSeniorDays(jwt: string, normWorkersSNO: number, seniorDays: number) {
    const params = { normWorkersSNO, seniorDays };
    await request(jwt, 'norm-worker-set-senior-days', params);
}

export async function setEmployeeCareDays(jwt: string, normWorkersSNO: number, careDays: number) {
    const params = { normWorkersSNO, careDays };
    await request(jwt, 'norm-worker-set-care-days', params);
}

export async function setEmployeeTimeOfInLieuHours(jwt: string, normWorkersSNO: number, timeOffInLieuHours: number) {
    const params = { normWorkersSNO, timeOffInLieuHours };
    await request(jwt, 'norm-worker-set-time-off-in-lieu-hours', params);
}

export async function updateEmployeePrimaryTeam(jwt: string, normWorkersSNO: number, normTeamsSNOPrimary: number) {
    await request(jwt, 'norm-worker-set-primary-team', {
        normWorkersSNO,
        normTeamsSNOPrimary,
    });
}
