import { useState } from 'react';
import { Card, Container, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getAllPeriods } from '../api/norm-periods';
import { useAppContext } from '../app-context';
import { Footer } from '../components/footer';
import { InstitutionSelector } from '../components/institution-selector';
import useMappedNavigation, { MappedNavigate, Route } from '../hooks/use-mapped-navigation';
import '../index.css';
import { logout } from '../shared/logout';
import usePeriodStore from '../store/norm-period';
import { getCurrentPeriod } from '../util/periods/get-current-period';
import { ForgotPassword } from './forgot-password';
import { Login } from './login';
import { NoInstitutions } from './no-institutions';
import ResetOtp from './reset-otp';

interface Props {
    setLogon: (jwt: string, payload: LoginPayload) => void;
}

export function LoginPage(props: Props) {
    const { t } = useTranslation();
    const [screen, setScreen] = useState<'login' | 'forgot-password' | 'reset-otp'>('login');
    const [loginResponse, setLoginResponse] = useState<LoginResponse | undefined>(undefined);
    const navigate = useMappedNavigation();
    const state = useAppContext();
    const { setPeriod } = usePeriodStore();

    // User is either logged in or session was restored from local storage
    if (state.institution?.employeeRole) {
        const path = getNavigatedPathUponLogin(state.institution?.employeeRole);
        return <MappedNavigate to={path} />;
    }

    function onLogout() {
        logout(state);
        setLoginResponse(undefined);
        setScreen('login');
        navigate('root');
    }

    async function onLoggedIn(loginResponse: LoginSuccess) {
        setLoginResponse(loginResponse);

        if (loginResponse.payload.data.person.passwordIsOtp === 1) {
            setScreen('reset-otp');
            return;
        }

        const activeInstitution = getActiveInstitution(loginResponse);
        if (activeInstitution) {
            await onInstitutionSelected(loginResponse.payload.JWT, loginResponse.payload, activeInstitution);
            return;
        }

        setScreen('login');
    }

    async function onInstitutionSelected(jwt: string, payload: LoginPayload, institution: Institution) {
        props.setLogon(jwt, payload);

        try {
            const periods = await getAllPeriods(jwt);
            const currentPeriod = getCurrentPeriod(periods);

            if (currentPeriod) {
                setPeriod(currentPeriod);
            }
        } finally {
            const path = getNavigatedPathUponLogin(institution.employeeRole);
            navigate(path);
        }
    }

    const institutionCount =
        loginResponse !== undefined && loginResponse.isSuccess
            ? Object.keys(loginResponse.payload.data.institutions).length
            : 0;

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="/">{t('app.title')}</Navbar.Brand>
                </Container>
            </Navbar>
            <div className="app-background">
                <Container className="col-md-5">
                    <p>&nbsp;</p>
                    <Card>
                        <Card.Body>
                            {loginResponse === undefined && screen === 'login' && (
                                <Login
                                    onLoggedIn={onLoggedIn}
                                    onForgotPassword={() => setScreen('forgot-password')}
                                    onResetOtp={() => setScreen('reset-otp')}
                                />
                            )}
                            {loginResponse === undefined && screen === 'forgot-password' && (
                                <ForgotPassword onBack={() => setScreen('login')} />
                            )}
                            {loginResponse !== undefined && loginResponse.isSuccess && screen === 'reset-otp' && (
                                <ResetOtp
                                    loginPayload={loginResponse.payload}
                                    onLogout={onLogout}
                                    onLoggedIn={onLoggedIn}
                                />
                            )}
                            {loginResponse !== undefined &&
                                loginResponse.isSuccess &&
                                screen === 'login' &&
                                institutionCount > 0 && (
                                    <InstitutionSelector
                                        jwt={loginResponse.payload.JWT}
                                        person={loginResponse.payload.data.person}
                                        institutions={Object.values(loginResponse.payload.data.institutions)}
                                        onInstitutionSelected={onInstitutionSelected}
                                    />
                                )}
                            {loginResponse !== undefined &&
                                loginResponse.isSuccess &&
                                screen === 'login' &&
                                institutionCount === 0 && <NoInstitutions onLogout={onLogout} />}
                        </Card.Body>
                    </Card>
                    <p>&nbsp;</p>
                </Container>
            </div>
            <Footer />
        </>
    );
}

const getActiveInstitution = (loginResponse: LoginResponse) => {
    if (!loginResponse.isSuccess) {
        return undefined;
    }

    return loginResponse.payload.data.institutions.find((inst) => Boolean(inst.isActive));
};

const getNavigatedPathUponLogin = (role: string): Route => {
    switch (role) {
        case 'pedagogue':
        case 'pedagogicalAssistant':
        case 'substitute':
            return 'start';
        case 'manager':
            return 'workplan';
        default:
            return 'start';
    }
};
