/* eslint-disable @typescript-eslint/no-redeclare */
import { Literal, Static, Union } from 'runtypes';

const EMPLOYEE_ROLE_NAME_MAPPING: Record<EmployeeRole, string> = {
    manager: 'role.manager',
    pedagogue: 'role.pedagogue',
    pedagogicalAssistant: 'role.pedagogicalAssistant',
    substitute: 'role.substitute',
};

export const EmployeeRole = Union(
    Literal('manager'),
    Literal('pedagogue'),
    Literal('pedagogicalAssistant'),
    Literal('substitute')
);

export type EmployeeRole = Static<typeof EmployeeRole>;

export const employeeRoles = EmployeeRole.alternatives.map((alternative) => alternative.value);

export const getLabelByEmployeeRole = (role: EmployeeRole) => EMPLOYEE_ROLE_NAME_MAPPING[role];

export const getLabelByName = (role: string) => {
    try {
        return getLabelByEmployeeRole(EmployeeRole.check(role));
    } catch (e) {
        throw new Error(`Unknown employee role: ${e}`);
    }
};
