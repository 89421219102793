import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useEffectOnce } from 'usehooks-ts';
import { DEFAULT_LANGUAGE, getLocaleFromLanguage, setLanguage, setupLabels } from './api/i18n';
import { getPeriod } from './api/norm-periods';
import { AppProvider } from './app-context';
import PageError from './components/page-error';
import initGlobalSettings from './config/global';
import { logAllUnhandledRejection } from './error-handling';
import { getJwt, setJwt } from './jwt';
import { Routes } from './routes';
import { logout } from './shared/logout';
import { useInstitutionSettings } from './store/institution-settings';
import usePeriodStore from './store/norm-period';
import SupportBanner from './support/banner';
import { DEFAULT_LOCALE } from './types/locale';
import { setEnvironment } from './util/env';
import { getNormPeriod } from './util/storage';

interface IndexProps {
    state: AppState;
}

function Index(props: IndexProps) {
    const [state, setState] = useState<AppState>(props.state);
    const { period, setPeriod } = usePeriodStore();
    const { refresh: refreshInstitutionSettings } = useInstitutionSettings();

    useEffectOnce(() => {
        // if period from store has value, it's most likely hydrated from session storage
        if (period) {
            return;
        }
        if (props.state.normPeriod) {
            setPeriod(props.state.normPeriod);
        }
    });

    useEffect(() => {
        if (getJwt()) {
            refreshInstitutionSettings();
        }
    }, [refreshInstitutionSettings]);

    return (
        <AppProvider value={state}>
            <SupportBanner />
            <Routes state={state} setState={setState} />
        </AppProvider>
    );
}

const DEFAULT_THEME = '2021';
const DEFAULT_SHOW_THEME_SELECTOR = false;
const getActiveJWT = () => localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
const getActivePerson = () => localStorage.getItem('person') || sessionStorage.getItem('person');
const getActiveInstition = () => localStorage.getItem('active') || sessionStorage.getItem('active');
const getInstitution = () => localStorage.getItem('institution') || sessionStorage.getItem('institution');
const getEnv = () => localStorage.getItem('env') || sessionStorage.getItem('env');
const getActiveTheme = () => localStorage.getItem('theme') ?? sessionStorage.getItem('theme') ?? DEFAULT_THEME;
const mayShowThemeSelector = (): boolean =>
    (localStorage.getItem('themeSelector') ??
        sessionStorage.getItem('themeSelector') ??
        DEFAULT_SHOW_THEME_SELECTOR) === 'true';

async function initialize() {
    const showThemeSelector = mayShowThemeSelector();
    const theme = getActiveTheme();
    let person: LoginPerson = {
        personsSNO: 0,
        email: '',
        name: '',
        isSuperAdmin: 0,
        language: DEFAULT_LANGUAGE,
        locale: DEFAULT_LOCALE,
    };
    let state: AppState = {
        jwt: '',
        person,
        showThemeSelector,
        theme,
        active: {
            employeesSNO: 0,
            employeeRole: 'employee',
            institutionsSNO: 0,
            institutionName: '',
            isMiniManager: 0,
        },
        normPeriod: undefined,
        normPeriodMetadata: undefined,
        institution: undefined,
        updateLanguage(lang) {
            this.person.language = lang;
            this.person.locale = getLocaleFromLanguage(lang);
        },
    };

    const jwt = getActiveJWT();
    const personData = getActivePerson();
    const activeData = getActiveInstition();
    const institutionData = getInstitution();
    const env = getEnv();
    let language: string | undefined = undefined;

    if (jwt !== null && personData !== null && activeData && institutionData) {
        person = JSON.parse(personData);
        language = person.language;
        const institution = JSON.parse(institutionData);

        let period: Period | undefined = undefined;
        const currentNormPeriod = getNormPeriod();
        if (currentNormPeriod?.normPeriodsSNOCurrent) {
            period = await getPeriod(jwt, currentNormPeriod.normPeriodsSNOCurrent).catch((_) => undefined);
        }

        if (env) {
            setEnvironment(env);
        }
        state = {
            ...state,
            jwt,
            person,
            active: JSON.parse(activeData),
            normPeriod: period,
            normPeriodMetadata: currentNormPeriod,
            institution,
        };
        setJwt(jwt);
    } else {
        logout(state, false);
    }

    await setupLabels(language);

    if (language) {
        setLanguage(language);
    }

    ReactDOM.render(
        <React.StrictMode>
            <ErrorBoundary FallbackComponent={PageError}>
                <Index state={state}></Index>
            </ErrorBoundary>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

initGlobalSettings();

initialize();

logAllUnhandledRejection();
