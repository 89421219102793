// @TODO - Stephen

import { FC } from 'react';
import MenuItem, { MenuItemProps } from '../components/menu-item';

//    - How do we type roles?
export type MenuItemType =
    | {
          type: 'logo';
          label: string;
          logo: string;
          roles: [];
      }
    | {
          type: 'version';
          label: string;
          roles: [];
      }
    | {
          type: 'institutionName';
          roles: [];
      }
    | {
          type: 'menuHeader';
          label: string;
          roles: string[];
      }
    | {
          type: 'menuItem';
          label: string;
          route: string;
          icon: string;
          roles: string[];
      }
    | {
          type: 'customMenuItem';
          label: string;
          route: string;
          icon: string;
          roles: string[];
          component: FC<MenuItemProps>;
      };

export const menuItems: MenuItemType[] = [
    {
        type: 'logo',
        logo: '/logo.svg',
        label: 'Aarsnorm',
        roles: [],
    },
    {
        type: 'institutionName',
        roles: [],
    },
    {
        type: 'menuItem',
        label: 'menu.home',
        route: '/start',
        icon: 'home',
        roles: [],
    },
    {
        type: 'menuItem',
        label: 'menu.quickstart',
        route: '/hurtigstart-guide',
        icon: 'lightbulb',
        roles: [],
    },
    {
        type: 'menuItem',
        label: 'menu.calendar',
        route: '/kalender',
        icon: 'calendar',
        roles: [],
    },
    {
        type: 'menuItem',
        label: 'menu.conversations',
        route: '/samtaler',
        icon: 'comment',
        roles: ['superAdmin'],
    },
    {
        type: 'customMenuItem',
        label: 'menu.notifications',
        route: '/notifikationer',
        icon: 'bell',
        roles: [],
        component: MenuItem.Notification,
    },
    {
        type: 'menuItem',
        label: 'menu.time-overview',
        route: '/tidsoversigt',
        icon: 'clock',
        roles: ['pedagogue', 'pedagogicalAssistant', 'substitute'],
    },
    {
        type: 'menuItem',
        label: 'menu.bonus-overview-own',
        route: '/min-bonus-oversigt',
        icon: 'user-plus',
        roles: ['pedagogue', 'pedagogicalAssistant', 'substitute'],
    },
    {
        type: 'menuItem',
        label: 'menu.shift-alteration',
        route: '/vagt-oensker',
        icon: 'hand',
        roles: ['pedagogue', 'pedagogicalAssistant', 'substitute'],
    },
    {
        type: 'menuHeader',
        label: 'menu.section.manager',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.shift-alteration-approval',
        route: '/vagt-oensker-godkendelse',
        icon: 'retweet',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.workplan',
        route: '/workplan',
        icon: 'clipboard-user',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.workplan-gantt',
        route: '/workplan-gantt',
        icon: 'table-list',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.work-schedule',
        route: '/work-schedule',
        icon: 'chart-bar',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.planning',
        route: '/planlaegning',
        icon: 'calendar-check',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.meeting-planner',
        route: '/norm-moeder',
        icon: 'people-group',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.norm-day-type-rollout',
        route: '/normtidstyper/udrulning',
        icon: 'person-circle-question',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.time-usage-overview',
        route: '/tidsforbrug-oversigt',
        icon: 'hourglass-half',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.bonus-overview',
        route: '/bonus-oversigt',
        icon: 'business-time',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.tilsyn-overview',
        route: '/tilsyn-oversigt',
        icon: 'person-walking-luggage',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.planning-asst',
        route: '/planlaegningsassistent',
        icon: 'calendar-plus',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuHeader',
        label: 'menu.settings',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'institutions-overview.header',
        route: '/institutionsoversigt',
        icon: 'industry',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.employees',
        route: '/ansatte',
        icon: 'user',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.norm-workers',
        route: '/normarbejder',
        icon: 'user-clock',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.teams',
        route: '/teams',
        icon: 'users',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.periods',
        route: '/normperioder',
        icon: 'table-cells',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.norm-time-types',
        route: '/tidstyper',
        icon: 'clock',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuItem',
        label: 'menu.settings',
        route: '/indstillinger',
        icon: 'gear',
        roles: ['manager', 'miniManager'],
    },
    {
        type: 'menuHeader',
        label: 'menu.section.developer',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.dateformat',
        route: '/super-admin/dateformats',
        icon: 'calendar',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.working-hours',
        route: '/super-admin/working-hours',
        icon: 'wrench',
        roles: ['superAdmin'],
    },
    {
        type: 'menuHeader',
        label: 'menu.section.super-admin',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.lockedUsers',
        route: '/super-admin/lockedUsers',
        icon: 'lock',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.quickstart',
        route: '/hurtigstart-guide-rediger',
        icon: 'lightbulb',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.labels',
        route: '/super-admin/labels',
        icon: 'spell-check',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.institution',
        route: '/super-admin/institution',
        icon: 'building-columns',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.agreements',
        route: '/super-admin/overenskomster',
        icon: 'handshake',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.dates',
        route: '/super-admin/dates',
        icon: 'school-circle-exclamation',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.super-admin-tools',
        route: '/super-admin/tools',
        icon: 'screwdriver-wrench',
        roles: ['superAdmin'],
    },
    {
        type: 'menuHeader',
        label: 'menu.section.logging',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.emailLog',
        route: '/super-admin/emaillog',
        icon: 'inbox',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.js-logs',
        route: '/super-admin/js-logs',
        icon: 'clipboard-list',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.fe-logs',
        route: '/super-admin/fe-logs',
        icon: 'file-waveform',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.be-logs',
        route: '/super-admin/be-logs',
        icon: 'file-waveform',
        roles: ['superAdmin'],
    },
    {
        type: 'menuItem',
        label: 'menu.request-logs',
        route: '/super-admin/request-logs',
        icon: 'share',
        roles: ['superAdmin'],
    },
    {
        type: 'version',
        label: process.env.REACT_APP_VERSION || '',
        roles: [],
    },
];
