import cx from 'classnames';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import sha1 from 'sha1';
import { setPersonPasswordOtpToReal } from '../api/person';
import FaIcon from '../components/fa-icon';
import { PasswordCriteria, validatePassword } from '../shared/validate-password';

interface Props {
    loginPayload: LoginPayload;
    onLogout: () => void;
    onLoggedIn: (loginSuccess: LoginSuccess) => void;
}

const ResetOtp: FunctionComponent<Props> = ({ loginPayload, onLogout, onLoggedIn }) => {
    const { t } = useTranslation();
    const passwordFieldRef = useRef<HTMLInputElement>(null);

    const [newPlainTextPassword, setNewPlainTextPassword] = useState('');
    const [passwordChecklist, setPasswordChecklist] = useState<PasswordCriteria | undefined>();
    const [revealPassword, setRevealPassword] = useState(false);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    // initialise password checklist to have the checklist appear.
    useEffect(() => {
        setPasswordChecklist(validatePassword('', t));
    }, [t]);

    async function onSubmit() {
        const { JWT, data } = loginPayload;

        setError(false);
        setLoading(true);

        try {
            const email = data.person.email.toLowerCase();

            const hashedPassword = sha1(email + ':' + newPlainTextPassword);
            const loginPayload = await setPersonPasswordOtpToReal(JWT, hashedPassword);

            onLoggedIn({ isSuccess: 1, responseCode: '200', payload: loginPayload });
        } catch (e) {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <h2>{t('reset-otp.header')}</h2>
            <p>{t('reset-otp.description')}</p>
            {error && <Alert variant="danger">{t('technical-error')}</Alert>}
            <div className="row">
                <div className="col-xs-10 col-sm-8 col-md-10 col-lg-10 col-xl-7">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        <Form.Group className="mb-3">
                            <Form.Label>{t('reset-otp.new-password')}</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    onChange={({ target: { value } }) => {
                                        setNewPlainTextPassword(value);
                                        setPasswordChecklist(validatePassword(value, t));
                                    }}
                                    ref={passwordFieldRef}
                                />
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => {
                                        if (passwordFieldRef.current) {
                                            passwordFieldRef.current.focus();
                                            passwordFieldRef.current.type = revealPassword ? 'password' : 'text';
                                        }
                                        setRevealPassword((val) => !val);
                                    }}
                                >
                                    <FaIcon icon={revealPassword ? 'eye-slash' : 'eye'} compact />
                                </Button>
                            </InputGroup>
                            {passwordChecklist && (
                                <ul className="mt-3" style={{ listStyle: 'none' }}>
                                    {Object.entries(passwordChecklist).map(([key, item]) => (
                                        <li key={key}>
                                            <span style={{ marginLeft: '-22px' }}>
                                                {item.check === 'passed' ? (
                                                    <FaIcon icon="circle-check" colour="var(--bs-success)" />
                                                ) : (
                                                    <FaIcon icon="circle-xmark" colour="var(--bs-danger)" />
                                                )}
                                            </span>
                                            <span
                                                className={cx(
                                                    { 'text-success': item.check === 'passed' },
                                                    { 'text-danger': item.check === 'failed' }
                                                )}
                                            >
                                                {item.message}
                                            </span>
                                        </li>
                                    ))}
                                    <li></li>
                                </ul>
                            )}
                        </Form.Group>
                    </form>
                </div>
            </div>
            <div className="d-flex gap-3 mt-2">
                <Button
                    variant="primary"
                    onClick={onSubmit}
                    disabled={
                        !passwordChecklist || Object.values(passwordChecklist).some((val) => val.check === 'failed')
                    }
                >
                    {t('reset-otp.button.reset')}
                    {loading && (
                        <>
                            &nbsp;
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </>
                    )}
                </Button>
                <Button
                    variant="secondary"
                    type="button"
                    onClick={() => {
                        onLogout();
                    }}
                >
                    {t('reset-otp.button.logout')}
                </Button>
            </div>
        </>
    );
};

export default ResetOtp;
