import cx from 'classnames';
import { getDay } from 'date-fns';
import { FC } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { notificationSetCompleted } from '../../api/notifications';
import { ShiftNoteCreatedData } from '../../api/notifications.type';
import { useAppContext } from '../../app-context';
import { formatAndRoundDate, formatShortDate, getDayOfWeekLabel } from '../../util/datetime';
import styles from '../notifications.module.css';
import { ItemProps } from './type';

const NotificationShiftNoteCreated: FC<ItemProps<ShiftNoteCreatedData>> = ({
    notificationsSNO,
    data,
    afterRequestHandled,
}) => {
    const { t } = useTranslation();
    const state = useAppContext();

    const handleShiftChange = () => {
        notificationSetCompleted(state.jwt, notificationsSNO).then(() => afterRequestHandled?.(notificationsSNO));
    };

    const getDisplayDate = () => {
        const shiftDate = new Date(data.timeStart);

        const getDisplayDate = formatShortDate(shiftDate, state.person.locale);
        const getDisplayDay = getDayOfWeekLabel(getDay(shiftDate));

        return t(getDisplayDay) + ', ' + getDisplayDate;
    };

    const getShiftChangeContent = (data: ShiftNoteCreatedData) => {
        const startAsDate = new Date(data.timeStart);
        const endAsDate = new Date(data.timeEnd);
        const startTime = formatAndRoundDate(startAsDate, 'HH:mm');
        const endTime = formatAndRoundDate(endAsDate, 'HH:mm');
        return (
            <>
                <div className={cx(styles['item-text'], 'mb-3')}>
                    {t('notification.shift-note-created.body-1')} <b>{getDisplayDate()}</b>{' '}
                    {t('notification.shift-note-created.body-2')}{' '}
                    <b>
                        {data.timeType.nameShort}, {startTime} - {endTime}
                    </b>
                    :
                </div>
                <p style={{ whiteSpace: 'pre-line' }}>{data.note}</p>
            </>
        );
    };

    return (
        <Card className={`${styles.item} shadow-sm`}>
            <Card.Body>
                <Row>
                    <Col>
                        <h4 className="mb-2">{t('notification.shift-note-created.title')}</h4>
                        {getShiftChangeContent(data)}
                    </Col>

                    <Col className="d-flex justify-content-end align-items-end gap-4">
                        <Button
                            variant="success"
                            size="lg"
                            onClick={() => {
                                handleShiftChange();
                            }}
                        >
                            {t('notification.shift-change.ok')}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default NotificationShiftNoteCreated;
