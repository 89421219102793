import { SessionStorageKey } from '../types/session-storage';

export class SessionStorage {
    public static getItem(key: SessionStorageKey): string | null {
        return window.sessionStorage.getItem(key);
    }

    public static setItem(key: SessionStorageKey, value: string) {
        window.sessionStorage.setItem(key, value);
    }

    public static removeItem(key: SessionStorageKey) {
        window.sessionStorage.removeItem(key);
    }
}
