import * as st from 'simple-runtypes';

export const NormWorker = st.sloppyRecord({
    normWorkersSNO: st.number(),
    employeesSNO: st.number(),
    normPeriodsSNO: st.number(),
    normTeamsSNOPrimary: st.number(),
    employeeAgreementReduction: st.number(),
    isVisibleInWorkplan: st.number(),
    notes: st.nullOr(st.string()),
    remainingInstitutionHours: st.nullOr(st.number()),
    sort: st.number(),
    employeeRole: st.string(),
    name: st.string(),
    shortName: st.string(),
    agreementBase: st.nullOr(st.string()),
});

export type NormWorker = ReturnType<typeof NormWorker>;
