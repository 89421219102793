import { FC } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { notificationSetCompleted } from '../../api/notifications';
import { UserLockedOut } from '../../api/notifications.type';
import { useAppContext } from '../../app-context';
import useMappedNavigation from '../../hooks/use-mapped-navigation';
import styles from '../notifications.module.css';
import { ItemProps } from './type';

const NotificationUserLockedOut: FC<ItemProps<UserLockedOut>> = ({ data, notificationsSNO, afterRequestHandled }) => {
    const { jwt } = useAppContext();
    const { t } = useTranslation();
    const navigate = useMappedNavigation();

    const handleLockedOut = () => {
        notificationSetCompleted(jwt, notificationsSNO);
        navigate('locked-users');
    };

    const handleDismiss = () => {
        notificationSetCompleted(jwt, notificationsSNO).then(() => afterRequestHandled?.(notificationsSNO));
    };

    return (
        <Card className={styles.item}>
            <Card.Body>
                <Row>
                    <Col>
                        <h4>{t('notification.locked-user.title')}</h4>
                        <div className={styles['item-text']}>
                            {data.person.name} {t('notification.locked-user.body')}
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center gap-4">
                        <>
                            <Button
                                variant="primary"
                                size="lg"
                                onClick={() => {
                                    handleLockedOut();
                                }}
                            >
                                {t('notification.locked-user.process')}
                            </Button>
                            <Button
                                variant="success"
                                size="lg"
                                onClick={() => {
                                    handleDismiss();
                                }}
                            >
                                {t('notification.shift-change.ok')}
                            </Button>
                        </>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default NotificationUserLockedOut;
