import { ApiError } from './api-error';

export class HttpError extends ApiError {
    public readonly originalMessage: string | undefined;

    constructor(requestId: string, message: string, private _payload?: ApiErrorPayload) {
        super(requestId, message);
    }

    public get payload() {
        return this._payload;
    }
}
