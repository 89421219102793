import * as st from 'simple-runtypes';

export const Setting = st.sloppyRecord({
    type: st.stringLiteralUnion('string', 'number', 'time'),
    section: st.string(),
    key: st.string(),
    value: st.nullOr(st.string()),
    default: st.string(),
    validators: st.sloppyRecord({
        regex: st.nullOr(st.string()),
        lengthMin: st.nullOr(st.number()),
        lengthMax: st.nullOr(st.number()),
        valueMin: st.nullOr(st.number()),
        valueMax: st.nullOr(st.number()),
    }),
});

export type Setting = ReturnType<typeof Setting>;

export const Settings = st.array(Setting);
