import { FunctionComponent } from 'react';
import { Spinner as BootstrapSpinner, SpinnerProps } from 'react-bootstrap';
import { Optional } from '../types/util';

type Props = {
    center?: boolean;
} & Optional<SpinnerProps, 'animation'>;

/**
 * A wrapper component to react-bootstrap <Spinner/>,
 * where this spinner is able to align centre vertically and horizontally
 */
const Spinner: FunctionComponent<Props> = ({
    center = false,
    animation = 'border',
    variant = 'secondary',
    ...rest
}) => {
    const classes = center ? 'd-flex flex-fill flex-column align-items-center justify-content-center' : '';

    return (
        <div className={`${classes}`}>
            <BootstrapSpinner {...rest} animation={animation} variant={variant} />
        </div>
    );
};

export default Spinner;
