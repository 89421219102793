import cx from 'classnames';
import { FC } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { notificationSetCompleted } from '../../api/notifications';
import { NotificationTimeRequestData } from '../../api/notifications.type';
import { useAppContext } from '../../app-context';
import { formatI18nMessage } from '../../components/format-i18n';
import useMappedNavigation from '../../hooks/use-mapped-navigation';
import styles from '../notifications.module.css';
import { ItemProps } from './type';

const NotificationTimeRequest: FC<ItemProps<NotificationTimeRequestData>> = ({
    notificationsSNO,
    data,
    beforeRequestHandled,
    afterRequestHandled,
}) => {
    const { t } = useTranslation();
    const state = useAppContext();

    const navigate = useMappedNavigation();

    const handleOK = async () => {
        beforeRequestHandled?.(notificationsSNO);
        await notificationSetCompleted(state.jwt, notificationsSNO);
        afterRequestHandled?.(notificationsSNO);
    };

    const translateAction = useTranslateAction();

    return (
        <Card className={styles.item}>
            <Card.Body>
                <Row>
                    <Col>
                        <h4>{t('notification.time-request.title')}</h4>
                        <div className={cx(styles['item-text'], 'mb-3')}>
                            {formatI18nMessage(t('notification.time-request.body'), {
                                name: <b>{data.normWorker.name}</b>,
                                type: <b>{translateAction(data.type)}</b>,
                            })}
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-end gap-4">
                        <Button
                            as="a"
                            variant="primary"
                            size="lg"
                            onClick={() => navigate('shift-alterations-approval')}
                        >
                            {t('notification.time-request.view')}
                        </Button>
                        <Button variant="success" size="lg" onClick={handleOK}>
                            {t('notification.time-request.ok')}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default NotificationTimeRequest;

const useTranslateAction = () => {
    const { t } = useTranslation();

    return (action: NotificationTimeRequestData['type']) => t(`notification.time-request.action.${action}`);
};
