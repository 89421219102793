import { addMinutes, addSeconds } from 'date-fns';
import { getJwt } from '../jwt';
import {
    formatToIsoDate,
    formatToIsoDateTime,
    formatToIsoTime,
    formatToNormTimeEnd,
    formatToNormTimeStart,
} from '../util/datetime';
import {
    EmployeeNormTimeList,
    NormTimeCounts,
    NormTimeDTO,
    NormTimeHeadCountDTO,
    NormTimeHeadCountRequest,
    NormTimeHeadCountResponse,
    NormTimes,
    NormTimeTypeCounts,
    OpenersAndClosersShifts,
    WorkplanGantt,
} from './norm-time.type';
import { request, requestWithRuntype, requestWithType } from './request';

export async function fetchNormTimesForEmployee(normWorkersSNO: Number) {
    return await requestWithRuntype(EmployeeNormTimeList, getJwt(), 'norm-time-get-all-for-employee', {
        normWorkersSNO,
    });
}

export async function rolloutNormTimeAbsensePlanner(
    jwt: string,
    normTeamsSNO: number,
    normTimeTypesSNO: number,
    normWorkersSNO: number,
    dateEnd: string,
    dateStart: string
) {
    return await request(jwt, 'norm-time-absense-planner', {
        normTeamsSNO,
        normTimeTypesSNO,
        normWorkersSNO,
        dateEnd,
        dateStart,
    });
}

export async function fetchNormTimeHeadCountByNormPeriod(
    jwt: string,
    normPeriodsSNO: number,
    start: Date,
    end: Date
): Promise<NormTimeHeadCountDTO> {
    const payload = await requestWithType<NormTimeHeadCountResponse, NormTimeHeadCountRequest>(
        NormTimeHeadCountResponse,
        jwt,
        'norm-time-get-headcount',
        {
            normPeriodsSNO,
            dateStart: formatToIsoDate(start),
            dateEnd: formatToIsoDate(end),
        }
    );

    if (Array.isArray(payload)) {
        return {};
    }

    return payload;
}

export async function normTimeGetAllFromPeriod(
    normPeriodsSNO: number,
    dateStart: string,
    dateEnd: string
): Promise<NormTime[]> {
    const path = 'norm-time-get-all-from-period';
    return await requestWithRuntype(NormTimes, getJwt(), path, {
        normPeriodsSNO,
        dateStart,
        dateEnd,
    });
}

export async function getWinningTimeForRange(
    normPeriodsSNO: number,
    dateTimeStart: Date,
    dateTimeEnd: Date,
    normWorkersSNO: number | null
): Promise<WorkplanGantt> {
    const normWorksSNOArg = normWorkersSNO ? [normWorkersSNO] : [];
    const normWorkersSNOs = [];
    if (normWorkersSNO) {
        normWorkersSNOs.push(normWorkersSNO);
    }

    const args = {
        normPeriodsSNO,
        dateTimeStart: formatToIsoDateTime(dateTimeStart),
        dateTimeEnd: formatToIsoDateTime(dateTimeEnd),
        normWorkersSNOs: normWorksSNOArg,
    };

    const payload = requestWithRuntype(WorkplanGantt, getJwt(), 'winning-time-get-all-from-period', args);

    return payload;
}

export async function getWinningTimeWorkingHours(
    normPeriodsSNO: number,
    dateTimeStart: Date,
    dateTimeEnd: Date,
    normWorkersSNO: number
) {
    const endpoint = 'winning-time-get-all-from-period-working-hours';
    return await request(getJwt(), endpoint, {
        normPeriodsSNO,
        dateTimeStart: formatToIsoDate(dateTimeStart) + ' 00:00:00',
        dateTimeEnd: formatToIsoDate(dateTimeEnd) + ' 23:59:59',
        normWorkersSNO,
    });
}

export async function getOpenersAndClosersShiftsForRange(
    normPeriodsSNO: number,
    dateTimeStart: Date,
    dateTimeEnd: Date,
    normWorkersSNOs: number[] | null
): Promise<OpenersAndClosersShifts> {
    return await requestWithRuntype(
        OpenersAndClosersShifts,
        getJwt(),
        'norm-time-get-openers-and-closers-shifts-in-period',
        {
            normPeriodsSNO,
            dateTimeStart: formatToIsoDateTime(dateTimeStart),
            dateTimeEnd: formatToIsoDateTime(dateTimeEnd),
            normWorkersSNOs,
        }
    );
}

export function createNormTime(jwt: string, normTime: NormTimeNew): Promise<{ normTime: NormTime }> {
    const date = formatToIsoDate(normTime.timeStart);
    const timeStart = formatToIsoTime(normTime.timeStart);
    const timeEnd = formatToIsoTime(addMinutes(normTime.timeEnd, -1));

    const params = {
        ...normTime,
        date,
        timeStart,
        timeEnd,
        origin: 'workplan',
    };

    return request(jwt, 'norm-time-create', params);
}

export function normTimeCreateFullDay(
    jwt: string,
    normTeamsSNO: Number,
    date: string,
    normTimeTypesSNO: Number,
    normWorkersSNO: Number
): Promise<{ normTime: NormTime }> {
    const origin = 'workplan';
    const params = {
        normTeamsSNO,
        date,
        normTimeTypesSNO,
        normWorkersSNO,
        origin,
    };
    return request(jwt, 'norm-time-create-full-day', params);
}

export function updateNormTime(
    jwt: string,
    normTime: NormTimeUpdate
): Promise<{ affRows: number; normTime: NormTime }> {
    const { timeStart, timeEnd, ...normTimeArgs } = normTime;
    return request(jwt, 'norm-time-update', {
        ...normTimeArgs,
        timeStart: formatToNormTimeStart(timeStart),
        // End time stored in DB should be 1 second before, for comparison purpose.
        timeEnd: formatToNormTimeEnd(addSeconds(timeEnd, -1)),
    });
}

export function deleteNormTime(
    jwt: string,
    normTimeSNO: NormTime['normTimeSNO']
): Promise<Pick<NormTimeDTO, 'normTimeSNO'>> {
    const params = { normTimeSNO };
    return request(jwt, 'norm-time-delete', params);
}

export function normTimeCountFromCategoriesInPeriod(
    jwt: string,
    dateTimeStart: string,
    dateTimeEnd: string,
    normWorkersSNO: number,
    normTeamsSNO: number,
    normTimeCategoriesSNOs: number[]
): Promise<NormTimeCounts> {
    return request(jwt, 'norm-time-count-from-categories-in-period', {
        dateTimeStart,
        dateTimeEnd,
        normWorkersSNO,
        normTeamsSNO,
        normTimeCategoriesSNOs,
    });
}

export function normTimeCountFromTimeTypesInPeriod(
    jwt: string,
    dateTimeStart: string,
    dateTimeEnd: string,
    normWorkersSNO: number,
    normTeamsSNO: number,
    normTimeTypesSNOs: number[]
): Promise<NormTimeTypeCounts> {
    return request(jwt, 'norm-time-count-from-time-types-in-period', {
        dateTimeStart,
        dateTimeEnd,
        normWorkersSNO,
        normTeamsSNO,
        normTimeTypesSNOs,
    });
}

export function normTimeDeleteMultiple(jwt: string, normTimeSNOs: number[]) {
    return request(jwt, 'norm-time-delete-multiple', { normTimeSNOs });
}
