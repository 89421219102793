import { localeDa, localeEnGB, setOptions } from '@mobiscroll/react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import log from '../logging/logging';
import { SystemLocale } from '../types/locale';
import { request } from './request';

type Language = keyof typeof LANGUAGES;

export const LANGUAGES = {
    dk: { label: 'language.dk', htmlAttribute: 'da', locale: 'da_DK' },
    gb: { label: 'language.gb', htmlAttribute: 'en', locale: 'en_GB' },
};

export const DEFAULT_LANGUAGE: Language = 'dk';

export const getLocaleFromLanguage = (language: string | undefined | null): SystemLocale => {
    const lang = language?.toLowerCase();

    if (!lang || !Object.keys(LANGUAGES).includes(lang)) {
        return SystemLocale.of(LANGUAGES[DEFAULT_LANGUAGE].locale);
    }
    return SystemLocale.of(LANGUAGES[lang as keyof typeof LANGUAGES].locale);
};

export async function setupLabels(selected?: string) {
    const selectedLang = selected?.toLowerCase();

    const translationResults = await Promise.all(
        Object.keys(LANGUAGES).map((language) => fetchLabels(language as Language))
    );

    const resources = translationResults.reduce((acc, result) => {
        const [language, translation] = result;
        return {
            ...acc,
            [language]: { translation },
        };
    }, {});

    // initialize i18n
    i18n.use(initReactI18next).init({
        resources,
        lng: 'dk',
        interpolation: {
            escapeValue: false,
        },
    });

    if (selectedLang && isValidLanguage(selectedLang)) {
        console.debug(`Using selected language: ${selectedLang}`);
        i18n.changeLanguage(selectedLang);
    } else {
        resetLanguage();
    }
}

export function setLanguage(lang: string) {
    const language = lang?.toLowerCase();

    if (!language || !isValidLanguage(language)) {
        log.error(`'${language}' is not a valid language. Language remained unchanged.`, 'system:i18n');
        return;
    }

    i18n.changeLanguage(language);
    setHtmlLangAttribute(language);
    console.debug(`Language changed to '${language}'`);

    // set locale settings for mobiscroll timeline
    setOptions({ locale: language === 'gb' ? localeEnGB : localeDa });
}

export function resetLanguage() {
    i18n.changeLanguage(DEFAULT_LANGUAGE);
    console.debug(`Using default language`);
}

async function fetchLabels(language: Language): Promise<[string, Record<string, string>]> {
    let forceProduction = false;
    if (process.env.REACT_APP_LABELS_GET_FROM_PROD === '1') {
        forceProduction = true;
    }

    const remoteLabels = (await request(
        '',
        'labels-get',
        { countryCode: language.toUpperCase() },
        { forceProduction, useJwt: false }
    )) as LabelsResponse;

    return [language, remoteLabels];
}

function isValidLanguage(lang: any): lang is Language {
    return Object.keys(LANGUAGES).includes(lang);
}

function setHtmlLangAttribute(language: Language) {
    document.documentElement.setAttribute('lang', LANGUAGES[language].htmlAttribute);
}
