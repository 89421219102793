import React, { FunctionComponent } from 'react';
import { useAppContext } from '../app-context';

interface Props {
    children: React.ReactNode;
    /**
     * Header is shown outside of the card with <h3> styling.
     */
    header?: React.ReactNode;
    /**
     * Title is shown inside of the card with <h5> styling.
     */
    title?: string;
}

export const Content: FunctionComponent<Props> = (props: Props) => {
    const state = useAppContext();
    if (state.theme === 'old') {
        return (
            <>
                {props.title && <h5>{props.title}</h5>}
                {props.children}
            </>
        );
    }

    return (
        <>
            {props.header && <h1 className="h3 mt-3 mb-3">{props.header}</h1>}
            <div className="card mb-3">
                {props.title && (
                    <div className="card-header">
                        <h5 className="card-title mb-0">{props.title}</h5>
                    </div>
                )}
                <div className="card-body">{props.children}</div>
            </div>
        </>
    );
};
