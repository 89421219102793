import { getJwt } from '../jwt';
import { AppLogs } from './log.type';
import { request, requestWithType } from './request';

export async function logToBackend(message: string, stackTrace: string, tags?: string[]) {
    const endpoint = 'log-fe-report';
    const jwt = getJwt() ?? '';
    return await request('', endpoint, { message, tags: tags ?? [], jwt, stackTrace }, { useJwt: false });
}

export async function fetchAppLogs(...tags: string[]) {
    return await requestWithType(AppLogs, getJwt() ?? '', '/log-fe-get-all', { tags: tags ?? [] });
}

export async function pruneFeLogs(deleteOlderThanDays: Number) {
    const endpoint = 'log-fe-prune';
    return await request(getJwt(), endpoint, { deleteOlderThanDays });
}
export async function pruneAllLogs(deleteOlderThanDays: Number) {
    const endpoint = 'log-prune-all';
    return await request(getJwt(), endpoint, { deleteOlderThanDays });
}
