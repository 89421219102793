import { getFirstDayOfWeek } from './get-first-day-of-week';
import { getLastDayOfWeek } from './get-last-day-of-week';
import { isDateInPeriod } from './is-date-in-period';

export function getStartAndEndOfWeekInPeriod(dateInWeek: Date, period: Period) {
    let startDate;
    let endDate;

    const startOfWeek = getFirstDayOfWeek(dateInWeek);
    const endOfWeek = getLastDayOfWeek(dateInWeek);

    if (isDateInPeriod(startOfWeek, period)) {
        startOfWeek.setHours(0, 0, 0);
        startDate = startOfWeek;
    } else {
        startDate = period.start;
    }

    if (isDateInPeriod(endOfWeek, period)) {
        endOfWeek.setHours(23, 59, 59);
        endDate = endOfWeek;
    } else {
        endDate = period.end;
    }

    return { startDate, endDate };
}
