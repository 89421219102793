import { FC } from 'react';
import * as st from 'simple-runtypes';
import {
    Notification,
    NotificationTimeRequestData,
    NotificationTimeRequestResultData,
    PlanningAssistantRollout,
    RequestAccess,
    ShiftChangeData,
    ShiftNoteCreatedData,
    UserLockedOut,
} from '../../api/notifications.type';
import NotificationPlanningAssistantRollout from './planning-assistant-rollout';
import NotificationRequestAccess from './request-access';
import NotificationShiftChange from './shift-change';
import NotificationShiftNoteCreated from './shift-note-created';
import NotificationTimeRequest from './time-request';
import NotificationTimeRequestResult from './time-request-result';
import { ItemProps } from './type';
import NotificationUserLockedOut from './user-locked-out';

// Allow return of null to not show certain type(s) of notifications
const NOTIFICATION_ITEMS_MAPPING: Record<Notification['type'], [FC<ItemProps<any>>, st.Runtype<any>] | null> = {
    'support-request': [NotificationRequestAccess, RequestAccess],
    'planning-assistant-roll-out': [NotificationPlanningAssistantRollout, PlanningAssistantRollout],
    'user-is-locked-out': [NotificationUserLockedOut, UserLockedOut],
    'norm-worker-shift-change': [NotificationShiftChange, ShiftChangeData],
    'norm-worker-time-request': [NotificationTimeRequest, NotificationTimeRequestData],
    'norm-time-notes-create': [NotificationShiftNoteCreated, ShiftNoteCreatedData],
    'time-request-accept-to-employee-create': [NotificationTimeRequestResult, NotificationTimeRequestResultData],
    'time-request-accept-to-employee-update': [NotificationTimeRequestResult, NotificationTimeRequestResultData],
    'time-request-accept-to-employee-delete': [NotificationTimeRequestResult, NotificationTimeRequestResultData],
    'time-request-deny-to-employee-create': [NotificationTimeRequestResult, NotificationTimeRequestResultData],
    'time-request-deny-to-employee-update': [NotificationTimeRequestResult, NotificationTimeRequestResultData],
    'time-request-deny-to-employee-delete': [NotificationTimeRequestResult, NotificationTimeRequestResultData],
};

export const getNotificationComponentByType = <T extends any>(
    type: Notification['type']
): [FC<ItemProps<T>>, st.Runtype<T>] | null => {
    return NOTIFICATION_ITEMS_MAPPING[type] ?? null;
};
