import usePeriodStore from '../store/norm-period';
import usePlanningStore from '../store/planning';

export const useClearStore = () => {
    const { clear: clearPeriodStore } = usePeriodStore();
    const { clear: clearPlanningStore } = usePlanningStore();

    return () => {
        clearPeriodStore();
        clearPlanningStore();
    };
};
