import cx from 'classnames';
import { FunctionComponent } from 'react';

type RelativeSize = '2xs' | 'xs' | 'sm' | 'lg' | 'xl' | '2xl';
type LiteralSize = `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10}x`;

interface Props {
    /**
     * FA icon name, minus `fa-` prefix.
     */
    icon: string;
    className?: string;
    colour?: string;
    compact?: boolean;
    fixedWidth?: boolean;
    /**
     * All FA [sizes](https://fontawesome.com/docs/web/style/size), minus `fa-` prefix.
     */
    size?: RelativeSize | LiteralSize;
    style?: React.CSSProperties;
}

/**
 * A react component to create font-awesome icon, with possibilities specified in typescript props.
 *
 * Refer to [cheatsheet](https://fontawesome.com/docs/web/style/style-cheatsheet) for more customisation.
 */
const FaIcon: FunctionComponent<Props> = ({
    icon,
    className,
    colour,
    compact = false,
    size,
    fixedWidth = false,
    style,
}) => {
    const defaultStyle = style ?? {};
    const newStyle = { ...defaultStyle, ...(colour ? { color: colour } : {}) };

    return (
        <i
            className={cx(
                'fa',
                `fa-${icon}`,
                { [className!]: !!className },
                { [`fa-${size!}`]: !!size },
                { 'me-2': !compact },
                { 'fa-fw': !!fixedWidth }
            )}
            style={newStyle}
        ></i>
    );
};

export default FaIcon;
