import { useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { logToBackend } from '../api/log';
import { useAppContext } from '../app-context';
import { RuntypeError } from '../types/errors/runtype-error';
import { isDefined } from '../util/common';
import { SessionStorage } from '../util/session-storage';

interface Log {
    (message: unknown, ...tags: string[]): Promise<void>;
    readonly info: (message: unknown, ...tags: string[]) => Promise<void>;
    readonly warn: (message: unknown, ...tags: string[]) => Promise<void>;
    readonly error: (message: unknown, ...tags: string[]) => Promise<void>;
}

export const LEVEL_INFO = 'level:info';
export const LEVEL_WARN = 'level:warn';
export const LEVEL_ERROR = 'level:error';

const ENABLE_LOGGING = process.env.REACT_APP_ENABLE_LOGGING === '1';

const logger = (message: unknown, ...tags: string[]) => {
    if (process.env.NODE_ENV === 'development') {
        const logToConsole = getConsole(...tags);
        logToConsole(
            `${message} ${tags.map((tag) => `%c${tag}%c`).join(' ')}`,
            ...Array.from({ length: tags.length }).flatMap(() => [
                'background-color: dodgerblue; border-radius: 5px; color: white; padding: 3px',
                '',
            ])
        );
    }

    if (ENABLE_LOGGING) {
        const logMessage = sanitiseLogMessage(message);
        const stackTrace = SessionStorage.getItem('stack-trace') ?? '';
        return logToBackend(logMessage, stackTrace, tags);
    } else {
        return Promise.resolve();
    }
};

logger.info = (message: unknown, ...tags: string[]) => {
    return logger(message, LEVEL_INFO, ...tags);
};

logger.warn = (message: unknown, ...tags: string[]) => {
    return logger(message, LEVEL_WARN, ...tags);
};

logger.error = (message: unknown, ...tags: string[]) => {
    return logger(message, LEVEL_ERROR, ...tags);
};

const log: Log = logger;

export default log;

export const useFullPageError = () => {
    const state = useAppContext();
    const show = state.person?.showDetailedErrorMessages === 1;

    const errorHandler = useErrorHandler();

    return useCallback(
        (error: unknown) => {
            if (show && error instanceof RuntypeError) {
                errorHandler(error);
            } else {
                log.error((error as any).toString());
            }
        },
        [errorHandler, show]
    );
};

// Utility functions
const getConsole = (...tags: string[]) => {
    if (tags.includes(LEVEL_INFO)) {
        return console.info;
    } else if (tags.includes(LEVEL_WARN)) {
        return console.warn;
    } else if (tags.includes(LEVEL_ERROR)) {
        return console.error;
    } else {
        return console.log;
    }
};

const sanitiseLogMessage = (payload: unknown) => {
    if (!isDefined(payload)) {
        return '<message is null or undefined>';
    }

    if (payload instanceof Error) {
        return `${payload.message}\n${JSON.stringify(payload)}\n${payload.stack}`;
    }

    switch (typeof payload) {
        case 'string':
            return payload;
        case 'object':
            return payload.constructor.name === 'Object' ? JSON.stringify(payload) : payload.toString();
        default:
            return payload.toString();
    }
};
