import cx from 'classnames';
import { getDay } from 'date-fns';
import { FC } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { WinningTime } from '../../api/norm-time.type';
import { notificationSetCompleted } from '../../api/notifications';
import { ShiftChangeData } from '../../api/notifications.type';
import { useAppContext } from '../../app-context';
import { formatAndRoundDate, formatShortDate, getDayOfWeekLabel } from '../../util/datetime';
import styles from '../notifications.module.css';
import { ItemProps } from './type';

const NotificationShiftChange: FC<ItemProps<ShiftChangeData>> = ({ notificationsSNO, data, afterRequestHandled }) => {
    const { t } = useTranslation();
    const state = useAppContext();

    const handleShiftChange = () => {
        notificationSetCompleted(state.jwt, notificationsSNO).then(() => afterRequestHandled?.(notificationsSNO));
    };

    const getDisplayDate = () => {
        const shiftDate = data.date;

        const getDisplayDate = formatShortDate(shiftDate, state.person.locale);
        const getDisplayDay = getDayOfWeekLabel(getDay(shiftDate));

        return t(getDisplayDay) + ', ' + getDisplayDate;
    };

    const getShift = (shift: WinningTime) => {
        const startAsDate = new Date(shift.timeStart);
        const endAsDate = new Date(shift.timeEnd);
        const startTime = formatAndRoundDate(startAsDate, 'HH:mm');
        const endTime = formatAndRoundDate(endAsDate, 'HH:mm');

        return (
            <Row key={shift.normTimeSNO} xs={3} md={4} lg={6}>
                <Col>{shift.timeType.nameShort}</Col>
                <Col>{startTime}</Col>
                <Col>{endTime}</Col>
            </Row>
        );
    };

    function sortShifts(n1: WinningTime, n2: WinningTime) {
        const startN1 = new Date(n1.timeStart).getTime();
        const startN2 = new Date(n2.timeStart).getTime();

        return startN1 - startN2;
    }

    const getShiftChangeContentNoNotifications = () => {
        return (
            <div className={cx(styles['item-text'])}>
                {getDisplayDate()} {t('notification.shift-change.empty')}
            </div>
        );
    };

    const getShiftChangeContent = (data: ShiftChangeData) => {
        return (
            <>
                <div className={cx(styles['item-text'], 'mb-3')}>
                    {getDisplayDate()} {t('notification.shift-change.body')}
                </div>

                <Container>
                    <Row xs={3} md={4} lg={6}>
                        <Col>
                            <b>{t('notification.shift-change.type')}</b>
                        </Col>
                        <Col>
                            <b>{t('notification.shift-change.start')}</b>
                        </Col>
                        <Col>
                            <b>{t('notification.shift-change.end')}</b>
                        </Col>
                    </Row>
                    <hr />

                    {data.winningTime.sort(sortShifts).map((winTime) => getShift(winTime))}
                </Container>
            </>
        );
    };

    return (
        <Card className={styles.item}>
            <Card.Body>
                <Row>
                    <Col>
                        <h4>{t('notification.shift-change.title')}</h4>
                        {data.winningTime.length === 0
                            ? getShiftChangeContentNoNotifications()
                            : getShiftChangeContent(data)}
                    </Col>

                    <Col className="d-flex justify-content-end align-items-end gap-4">
                        <Button
                            variant="success"
                            size="lg"
                            onClick={() => {
                                handleShiftChange();
                            }}
                        >
                            {t('notification.shift-change.ok')}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default NotificationShiftChange;
