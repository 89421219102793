import { addSeconds } from 'date-fns';
import * as st from 'simple-runtypes';
import { parseIsoDate, parseIsoDateTime } from '../../util/datetime';

/**
 * e.g. 2023-02-28
 */
export const IsoDateRuntype = st.string({ match: /^\d{4}-\d{2}-\d{2}$/, trim: true });

/**
 * e.g. 2023-02-28 23:59:59
 */
// JJ - It failed and is too complex to debug
// match: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2]\d|3[0-1]) ([0|1]\d|2[0-3]):[0-5]\d:[0-5]\d$/,
export const IsoDateTimeRuntype = st.string({
    match: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
    trim: true,
});

export const DateRuntype = st.runtype((value: unknown) => {
    const isString = st.use(IsoDateRuntype, value);

    if (!isString.ok) {
        return isString.error;
    }

    const date = parseIsoDate(isString.result);

    if (isNaN(+date)) {
        return st.createError(`'${value}' is not a valid ISO8601 date format`);
    }

    return date;
});

export const DateTimeRuntype = st.runtype((value: unknown) => {
    const isString = st.use(IsoDateTimeRuntype, value);

    if (!isString.ok) {
        return isString.error;
    }

    const date = parseIsoDateTime(isString.result);

    if (isNaN(+date)) {
        return st.createError(`'${value}' is not a valid ISO8601 date format`);
    }

    return date;
});

// Automatically add 1 second to the end time as BE always return 1 second short (by convention)
export const EndDateTimeRuntype = st.runtype((value: unknown) => {
    const date = st.use(DateTimeRuntype, value);

    if (!date.ok) {
        return date.error;
    }

    return addSeconds(date.result, 1);
});

export const Timestamp = st.runtype((value: unknown) => {
    const isTimestamp = st.use(st.number(), value);

    if (!isTimestamp.ok) {
        return isTimestamp.error;
    }

    return new Date(isTimestamp.result);
});
