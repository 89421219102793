import { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../app-context';
import { SessionStorage } from '../util/session-storage';
import styles from './support.module.css';

const SupportBanner: FC = () => {
    const { t } = useTranslation();
    const [showBanner, setShowBanner] = useState(false);

    const { person } = useAppContext();

    const token = SessionStorage.getItem('support-token');

    useEffect(() => {
        setShowBanner(!!token);
    }, [token]);

    if (!showBanner) {
        return null;
    }

    const endSupport = () => {
        SessionStorage.removeItem('support-token');
        setShowBanner(false);
    };

    return (
        <div className={styles.banner}>
            <span className={styles.description}>{t('support-mode.description')}</span>

            {(person.isSuperAdmin === 1 || process.env.REACT_APP_SHOW_ENV_BANNER === '1') && (
                <div className={styles.supportToken}>{SessionStorage.getItem('support-token')}</div>
            )}

            <Button variant="light" onClick={endSupport}>
                {t('support-mode.button.end')}
            </Button>
        </div>
    );
};

export default SupportBanner;
