import { Fragment, FunctionComponent, ReactNode } from 'react';

interface Props {
    message: string;
    substitutions: Substitions<ReactNode>;
}

interface Substitions<T extends ReactNode | string> {
    [S: string]: T;
}

const FormatI18n: FunctionComponent<Props> = ({ message, substitutions }) => {
    return <>{formatI18nMessage(message, substitutions)}</>;
};

export default FormatI18n;

export const formatI18nMessage = (message: string, substitutions: Substitions<ReactNode>) => {
    const keys = Object.keys(substitutions);
    const regex = new RegExp(`([^{]*?)({(?:${keys.join('|')})})([^{]*)`, 'g');

    let match: ReturnType<RegExp['exec']>;
    let result: (ReactNode | null)[] = [];

    for (let i = 0; (match = regex.exec(message)); i++) {
        const arr = match.map((phrase, idx) => {
            // First element is the original message, skip it.
            if (idx === 0) {
                return null;
            }

            // a unique key to stop React from complaining
            const uniqueKey = `${i}-${idx}`;

            // check if this capturing group is the placeholder
            const placeholder = keys.find((key) => `{${key}}` === phrase);

            if (placeholder) {
                // if it's the placeholder, replace with what substitution
                return <Fragment key={uniqueKey}>{substitutions[placeholder]}</Fragment>;
            } else {
                // if it's not a placeholder, return the message as is
                return <Fragment key={uniqueKey}>{phrase}</Fragment>;
            }
        });
        result.push([...arr]);
    }

    return result.length > 0 ? result : <>{message}</>;
};

export const formatI18nMessageInPlainText = (message: string, substitutions: Substitions<string>) => {
    const keys = Object.keys(substitutions);
    const regex = new RegExp(`([^{]*?)({(?:${keys.join('|')})})([^{]*)`, 'g');

    let match: ReturnType<RegExp['exec']>;
    let result: (string | null)[] = [];

    for (let i = 0; (match = regex.exec(message)); i++) {
        const arr = match.map((phrase, idx) => {
            // First element is the original message, skip it.
            if (idx === 0) {
                return null;
            }

            // check if this capturing group is the placeholder
            const placeholder = keys.find((key) => `{${key}}` === phrase);

            if (placeholder) {
                // if it's the placeholder, replace with what substitution
                return substitutions[placeholder];
            } else {
                // if it's not a placeholder, return the message as is
                return phrase;
            }
        });
        result.push(...arr);
    }

    return result.join('');
};
