import { SystemLocale } from '../types/locale';

export type DateFormat = 'long' | 'short' | 'day/month/year' | 'day month year';

const LOCALE_MAP: Record<SystemLocale, string> = {
    da_DK: 'da-DK',
    en_GB: 'en-GB',
};

export const dateFormat = (input: string | Date, outputFormat: DateFormat, locale: SystemLocale) => {
    if (input instanceof Date) {
        return formatPicker(input, outputFormat, locale);
    }

    if (typeof input === 'string') {
        const inputAsDate = new Date(input);
        return formatPicker(inputAsDate, outputFormat, locale);
    }

    throw new Error('dateFormat input neither string nor Date: ' + JSON.stringify(input));
};

const formatPicker = (input: Date, outputFormat: DateFormat, locale: SystemLocale) => {
    switch (outputFormat) {
        case 'long':
            return formatToDateMonthLong(input, locale);
        case 'short':
            return formatToDateMonthShort(input, locale);
        case 'day/month/year':
            return formatToDateMonthYearSlashed(input);
        case 'day month year':
            return formatToDayMonthYear(input, locale);
        default:
            throw new Error('output is not a DateFormat: ' + JSON.stringify(outputFormat));
    }
};

const formatToDateMonthLong = (date: Date, locale: SystemLocale) => {
    const formatOptions: Intl.DateTimeFormatOptions = { weekday: 'long', day: 'numeric', month: 'long' };
    return date.toLocaleString(LOCALE_MAP[locale], formatOptions);
};

const formatToDateMonthShort = (date: Date, locale: SystemLocale) => {
    const formatOptions: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short' };
    return date.toLocaleString(LOCALE_MAP[locale], formatOptions);
};

const formatToDateMonthYearSlashed = (date: Date) => {
    const formatOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
    // Hardcoded the locale to always get
    // the format with slashes and not dots
    return date.toLocaleString('en-GB', formatOptions);
};

// New function to format 'day month year' like '1. august 2024'
const formatToDayMonthYear = (date: Date, locale: SystemLocale) => {
    const formatOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleString(LOCALE_MAP[locale], formatOptions);
};
