import { DEFAULT_LANGUAGE, resetLanguage } from '../api/i18n';
import { clearJwt } from '../jwt';
import { DEFAULT_LOCALE } from '../types/locale';

export function logout(state?: AppState, lang: boolean = true) {
    clearJwt();

    if (state) {
        state.jwt = '';
        state.person = {
            personsSNO: 0,
            email: '',
            name: '',
            isSuperAdmin: 0,
            language: DEFAULT_LANGUAGE,
            locale: DEFAULT_LOCALE,
        };
        state.institution = undefined;
    }

    if (lang) {
        resetLanguage();
    }

    window.localStorage.clear();
    window.sessionStorage.clear();
}
