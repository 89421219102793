import * as st from 'simple-runtypes';
import { getJwt } from '../jwt';
import { NormWorkerRemainingHours } from '../types/workplan';
import { PlanningTimeBreakdown } from './planning.type';
import { request, requestWithRuntype } from './request';

export async function fetchPlanningFigures(jwt: string, normWorkersSNO: number): Promise<PlanningFigures> {
    const response = await request(jwt, 'planning-get-main-numbers', {
        normWorkersSNO,
    });

    const { employeeRole, employments, planningRows, institutionHoursPerDay } = response;

    return {
        employeeRole,
        employments,
        planningRows,
        institutionHoursPerDay,
    };
}

export async function updateFigures(jwt: string, normWorkersSNO: number, timeType: string, fieldValue: number) {
    await request(jwt, 'norm-worker-absense-reservations-set-spending-field', {
        normWorkersSNO,
        timeType,
        fieldValue,
    });
}

export async function setNormWorkerNotes(jwt: string, normWorkersSNO: number, notes: string) {
    await request(jwt, 'norm-worker-set-notes', {
        normWorkersSNO,
        notes,
    });
}
export async function fetchPlanningTimeAllEmployees(normPeriodsSNO: number): Promise<PlanningTimeBreakdown[]> {
    return await request(getJwt(), 'planning-get-time-breakdown-for-all-employees', { normPeriodsSNO });
}

export async function fetchZombieShifts(normWorkersSNO: number) {
    return await request(getJwt(), 'norm-time-get-all-zombie-shifts', { normWorkersSNO });
}

export async function getRemainingInstitutionTimeForNormWorkers(
    normWorkersSNOs: number[]
): Promise<NormWorkerRemainingHours[]> {
    // Temporarily recalculates every time
    //
    // This should give fewer trust issues as
    // it will now be identical to the one in planning
    const calcFromScratch = 1;

    return await requestWithRuntype(
        st.array(NormWorkerRemainingHours),
        getJwt(),
        'planning-get-remaining-inst-time-for-list-of-norm-workers',
        {
            normWorkersSNOs,
            calcFromScratch,
        }
    );
}

export async function recalculateRemainingInstitutionTimeForAll(normPeriodsSNO: number) {
    return await request(getJwt(), 'planning-recalc-remaining-inst-time-for-all-norm-workers', { normPeriodsSNO });
}
