import classnames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'usehooks-ts';
import useIsMobileScreen from '../hooks/use-is-mobile-size';
// import usePeriod from '../hooks/use-period';
import { useAppContext } from '../app-context';
import { menuItems, MenuItemType } from '../config/menu-items';
import { getUserRoles, userHasMenuItemRole } from '../util/user-roles';
import MenuItem from './menu-item';
import './sidebar.css';

interface Props {
    pinned: boolean;
    isSuperAdmin: number;
}

export function Sidebar({ pinned, isSuperAdmin }: Props) {
    const { t } = useTranslation();
    const isMobile = useIsMobileScreen();
    const { institution } = useAppContext();
    const institutionName = institution?.institutionName ?? '';
    const userRoles = getUserRoles(isSuperAdmin, institution);

    // const p = usePeriod();
    // const is1706Active = p.hasPeriod && p.period.bonus1706 !== 'none' ? true : false;

    const [showMenu, setShowMenu] = useState(pinned);

    const [activateMouseOver, deactivateMouseOver] = useDebouncedMouseHover(() => setShowMenu(true), 800);
    const [activateMouseOut, deactivateMouseOut] = useDebouncedMouseHover(() => setShowMenu(false), 300);

    const onMouseEnter = () => {
        if (!isMobile) {
            activateMouseOver();
            deactivateMouseOut();
        }
    };
    const onMouseLeave = () => {
        if (!isMobile) {
            deactivateMouseOver();
            activateMouseOut();
        }
    };

    const menuHeader = (headerKey: string) => {
        return (
            <div className="row navbar-vertical-label-wrapper mt-3 mb-2">
                <div
                    className={classnames('col-auto navbar-vertical-label', {
                        collapsed: showMenu === false && pinned === false,
                    })}
                >
                    <span className="ms-3">{t(headerKey)}</span>
                </div>
                <hr className="mb-0 navbar-vertical-divider" />
            </div>
        );
    };

    const showMenuItems = (menuItems: MenuItemType[], institutionName: string) => {
        return menuItems.map((r, i) => {
            const { type } = r;

            switch (type) {
                case 'logo':
                    const { logo } = r;
                    return (
                        <div key={'menuItem-logo-' + i}>
                            <a
                                href="/"
                                className={classnames('menu-brand', {
                                    collapsed: showMenu === false && pinned === false,
                                })}
                            >
                                <span>
                                    <img alt="" src={logo} height="24" />
                                    {r.label}
                                </span>
                            </a>
                        </div>
                    );

                case 'institutionName':
                    return (
                        <div key={'menuItem-institution-name-' + i}>
                            <div
                                className={classnames('menu-brand-inst', {
                                    collapsed: showMenu === false && pinned === false,
                                })}
                            >
                                {institutionName}
                            </div>
                        </div>
                    );

                case 'menuHeader':
                    const { roles: menuHeaderRoles, label: headerLabel } = r;

                    if (!userHasMenuItemRole(userRoles, menuHeaderRoles)) {
                        return undefined;
                    }

                    return <div key={'menuItem-header-' + i}>{menuHeader(headerLabel)}</div>;

                case 'menuItem':
                    const { route, icon, roles: menuItemRoles, label } = r;
                    if (!userHasMenuItemRole(userRoles, menuItemRoles)) {
                        return undefined;
                    }

                    return (
                        <div key={'menuItem-item-' + i}>
                            <MenuItem label={label} route={route} icon={icon} />
                        </div>
                    );

                case 'customMenuItem': {
                    const { route, icon, roles: customMenuItemRoles, component: Component, label } = r;
                    if (!userHasMenuItemRole(userRoles, customMenuItemRoles)) {
                        return undefined;
                    }

                    return (
                        <div key={'menuItem-item-' + i}>
                            <Component label={label} route={route} icon={icon} />
                        </div>
                    );
                }

                case 'version':
                    return (
                        <div key={'menuItem-version-' + i}>
                            <li className="version menu-li">
                                <code>{r.label}</code>
                            </li>
                        </div>
                    );

                default:
                    throw new Error('Unknown type: ' + type);
            }
        });
    };

    return (
        <div
            className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
                collapsed: showMenu === false && pinned === false,
                small: isMobile,
            })}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            aria-expanded={showMenu || pinned}
        >
            <ul className="navigation navigation-main menu-ul">{showMenuItems(menuItems, institutionName)}</ul>
        </div>
    );
}

const useDebouncedMouseHover = (
    callback: () => void,
    duration: number
): readonly [activateTimeout: () => void, deactivateTimeout: () => void] => {
    const [debounceTimeout, setDebounceTimeout] = useState<number | null>(null);

    useInterval(callback, debounceTimeout);

    return [() => setDebounceTimeout(duration), () => setDebounceTimeout(null)] as const;
};
