import { request } from './api/request';
import { getJwt } from './jwt';
import log from './logging/logging';

export const logAllUnhandledRejection = () => {
    window.addEventListener('unhandledrejection', (event) => {
        logError(event.reason);
    });
};

export const logError = (error: any) => {
    const payload = {
        url: window.location.href,
        browser: navigator.userAgent,
        cookiesEnabled: navigator.cookieEnabled ? 1 : 0,
        language: navigator.language,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        browserWidth: window.innerWidth,
        browserHeight: window.innerHeight,
        jwt: getJwt() ?? '',
        ...getErrorFields(error),
    };

    request('', 'log-js-runtime-errors-report', payload, { useJwt: false }).catch((e1) =>
        log.error(`Unable to log error: ${e1.toString()}`, 'system:log').catch((e2) => {
            console.error(e1);
            console.error(e2);
        })
    );
};

const getErrorFields = (
    error: any
): {
    name: string;
    message: string;
    stackTrace: string;
} =>
    error instanceof Error
        ? {
              name: error.name,
              message: error.message,
              stackTrace: error.stack ?? '',
          }
        : {
              name: '',
              message: '',
              stackTrace: '',
          };
