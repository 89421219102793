export const notImplemented = () => {
    // @TODO
    //   - Is this the correct message?
    //   - Shouldn't it be "Method not implemented" ?
    throw new Error('Context not initialised');
};

export const isNil = (value: any): value is undefined | null => {
    if (typeof value === 'undefined') {
        return true;
    }

    if (value === null) {
        return true;
    }

    return false;
};

export const isDefined = <T = any>(value: T): value is NonNullable<T> => typeof value !== 'undefined' && value !== null;

export const sleep = (intervalInMs: number) => new Promise((resolve) => setTimeout(resolve, intervalInMs));
