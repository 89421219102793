import { useWindowSize } from 'usehooks-ts';

const MOBILE_SCREEN_WIDTH = 768;
const MOBILE_LANDSCAPE_HEIGHT = 568;

const useIsMobileScreen = () => {
    const size = useWindowSize();
    return size.width < MOBILE_SCREEN_WIDTH;
};

export const useIsMobileLandscape = () => {
    const size = useWindowSize();
    const isMobileWidth = size.width > MOBILE_SCREEN_WIDTH;
    const isLandscape = size.width > size.height;
    const isMobileLandscapeHeight = size.height < MOBILE_LANDSCAPE_HEIGHT;
    return isMobileWidth && isLandscape && isMobileLandscapeHeight;
};

export default useIsMobileScreen;
