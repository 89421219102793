import { debounce } from 'lodash-es';
import { createContext, CSSProperties, FC, useContext, useEffect, useRef, useState } from 'react';

interface Props {
    className?: string;
    style?: CSSProperties;
}

const ContentDimensionContext = createContext<number | undefined>(undefined);

const MainContent: FC<Props> = ({ children, ...rest }) => {
    const mainRef = useRef<HTMLElement>(null);
    const [contentSize, setContentSize] = useState<number>();

    useEffect(() => {
        if (!mainRef.current) {
            return;
        }

        const mainContentResizeObserver = new ResizeObserver((entries) => {
            // Use debounce to avoid retriggering before the painting is done
            // Ref: https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications#answer-77591424
            debounce(
                () => {
                    const size = entries.find((entry) => entry.target === mainRef.current)?.borderBoxSize[0]
                        ?.inlineSize;
                    if (size) {
                        setContentSize(size);
                    }
                },
                100,
                { leading: false, trailing: true }
            );
        });

        mainContentResizeObserver.observe(mainRef.current);

        return () => mainContentResizeObserver.disconnect();
    }, []);

    return (
        <ContentDimensionContext.Provider value={contentSize}>
            <main {...rest} ref={mainRef}>
                {children}
            </main>
        </ContentDimensionContext.Provider>
    );
};

export default MainContent;

export const useContentDimension = () => {
    const ctx = useContext(ContentDimensionContext);
    return ctx ?? window.innerWidth;
};
