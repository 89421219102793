import { useEffect } from 'react';

const focusHook = (eventType: 'focus' | 'blur') => (listener: (evt: FocusEvent) => any) => {
    useEffect(() => {
        window.addEventListener(eventType, listener);

        return () => window.removeEventListener(eventType, listener);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- Prevent circular re-render
    }, []);
};

export const useWindowFocus = focusHook('focus');

export const useWindowBlur = focusHook('blur');
