import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import sha1 from 'sha1';
import { login } from '../api/person';

interface Props {
    onLoggedIn: (loginResponse: LoginSuccess, period?: Period) => void;
    onForgotPassword: () => void;
    onResetOtp: () => void;
}

export function Login(props: Props) {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [tooManyAttempts, setTooManyAttempts] = useState(-1);
    const { t } = useTranslation();

    useEffect(
        function () {
            if (tooManyAttempts >= 0) {
                TIMEOUT = setTimeout(function () {
                    setTooManyAttempts(tooManyAttempts - 1);
                }, 1000);
            }
        },
        [tooManyAttempts]
    );

    async function onSubmit() {
        if (!validateRequiredFields()) {
            return;
        }

        setLoading(true);
        setFormError('');

        try {
            const lowercaseEmail = email.toLowerCase();
            const hashedPassword = sha1(lowercaseEmail + ':' + password);
            const response = await login(lowercaseEmail, hashedPassword);

            if (response.isSuccess) {
                props.onLoggedIn(response);
            } else {
                validateResponse(response);
            }
        } catch (e) {
            setFormError('EXCEPTION');
        } finally {
            setLoading(false);
        }
    }

    function validateRequiredFields(): boolean {
        if (email.length === 0) {
            setEmailError('REQUIRED');
        }
        if (password.length === 0) {
            setPasswordError('REQUIRED');
        }
        return email.length > 0 && password.length > 0;
    }

    function validateResponse(result: LoginResponse) {
        switch (result.responseCode) {
            case 'LOGIN_UNKNOWN_EMAIL':
                setEmailError('LOGIN_UNKNOWN_EMAIL');
                break;

            case 'LOGIN_INVALID_PASSWORD':
                setPasswordError('LOGIN_INVALID_PASSWORD');
                break;

            case 'LOGIN_TEMPORARILY_LOCKED_OUT':
                if (isPayloadLockedOut(result.payload)) {
                    setPasswordError('LOGIN_INVALID_PASSWORD');
                    setTooManyAttempts(result.payload.remainingSecondsOfLockout);
                }
                break;

            case 'ARGUMENTS_INVALID':
                if (!isPayloadLockedOut(result.payload)) {
                    if (result.payload.missingOrInvalidArguments?.email) {
                        setEmailError('INVALID_EMAIL');
                    } else {
                        setFormError('EXCEPTION');
                    }
                }
                break;

            default:
                setFormError('EXCEPTION');
        }
    }

    const onFocusEmail = () => setEmailError('');
    const onFocusPassword = () => setPasswordError('');

    return (
        <>
            <h2>{t('frontpage.header')}</h2>

            {tooManyAttempts >= 0 && (
                <div
                    className="alert alert-danger alert-dismissible fade show ps-3 pt-3 pb-3 pe-3 flex-wrap"
                    role="alert"
                >
                    <strong>
                        <i className="fas fa-lg fa-exclamation-triangle"></i> {t('login.too-many-attempts.1')}
                    </strong>{' '}
                    {t('login.too-many-attempts.2')} {tooManyAttempts}
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            clearTimeout(TIMEOUT);
                            setTooManyAttempts(-1);
                        }}
                    ></button>
                </div>
            )}

            {formError === 'EXCEPTION' && <Alert variant="danger">{t('login.error')}</Alert>}

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                <label className="form-label" htmlFor="email">
                                    {t('login.email')}
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    disabled={loading}
                                    className={'form-control' + (emailError !== '' ? ' is-invalid' : '')}
                                    defaultValue={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onFocus={onFocusEmail}
                                />
                                <div className="invalid-feedback">
                                    {emailError === 'REQUIRED' && t('login.email.required')}
                                    {emailError === 'LOGIN_UNKNOWN_EMAIL' && t('login.email.unknown')}
                                    {emailError === 'INVALID_EMAIL' && t('login.email.invalid')}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                <label className="form-label" htmlFor="password">
                                    {t('login.password')}
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    disabled={loading}
                                    className={'form-control' + (passwordError !== '' ? ' is-invalid' : '')}
                                    defaultValue={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onFocus={onFocusPassword}
                                />
                                <div className="invalid-feedback">
                                    {passwordError === 'REQUIRED' && t('login.password.required')}
                                    {passwordError === 'LOGIN_INVALID_PASSWORD' && t('login.password.invalid')}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                <div className="d-grid gap-2 d-md-block">
                                    <button
                                        type="submit"
                                        className="btn btn-lg btn-primary"
                                        disabled={loading || tooManyAttempts >= 0}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSubmit();
                                        }}
                                    >
                                        {t('login.button')}
                                        {loading && (
                                            <>
                                                &nbsp;{' '}
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <a
                                href="#/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onForgotPassword();
                                }}
                            >
                                {t('frontpage.forgot-password')}
                            </a>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-4">
                        <h3>{t('frontpage.support')}</h3>
                        <a href={'tel:' + t('frontpage.phone')}>{t('frontpage.phone')}</a>
                        <br />
                        <a href={'mailto:' + t('frontpage.email')}>{t('frontpage.email')}</a>
                        <br />
                        <a href={'https://' + t('frontpage.web') + '/'}>{t('frontpage.web')}</a>
                    </div>
                </div>
            </form>
            <div className="mt-4">
                <p>{t('frontpage.about1')}</p>
                <p>{t('frontpage.about2')}</p>
            </div>
        </>
    );
}

let TIMEOUT: NodeJS.Timeout;

const isPayloadLockedOut = (value: LoginPayload | LoginLockout): value is LoginLockout => {
    return value.hasOwnProperty('remainingSecondsOfLockout');
};
