import usePeriodStore from '../store/norm-period';
import { MappedNavigate } from './use-mapped-navigation';

type PeriodHook =
    | {
          hasPeriod: true;
          period: Period;
      }
    | {
          hasPeriod: false;
          selector: React.ReactElement;
      };

const usePeriod = (): PeriodHook => {
    const { period } = usePeriodStore();

    if (period) {
        return {
            hasPeriod: true,
            period,
        };
    }

    return {
        hasPeriod: false,
        selector: <MappedNavigate to="norm-period-select" />,
    };
};

export default usePeriod;
