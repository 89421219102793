import { keyBy } from 'lodash-es';
import { create } from 'zustand';
import { getSettings } from '../api/settings';
import { Setting } from '../api/settings.type';
import { RemoteStatus } from '../types/remote';

export type InstitutionSettings = State;

type State = {
    [K in 'openingHours' | 'closingHours']: {
        mon: string | undefined;
        tue: string | undefined;
        wed: string | undefined;
        thu: string | undefined;
        fri: string | undefined;
        sat: string | undefined;
        sun: string | undefined;
    };
} & {
    settings: Setting[] | undefined;
    status: RemoteStatus;
};

interface Action {
    refresh: () => Promise<void>;
}

const initialState: State = {
    openingHours: {
        mon: undefined,
        tue: undefined,
        wed: undefined,
        thu: undefined,
        fri: undefined,
        sat: undefined,
        sun: undefined,
    },
    closingHours: {
        mon: undefined,
        tue: undefined,
        wed: undefined,
        thu: undefined,
        fri: undefined,
        sat: undefined,
        sun: undefined,
    },
    settings: undefined,
    status: 'idle',
};

export const useInstitutionSettings = create<State & Action>((set) => ({
    ...initialState,
    refresh: async () => {
        set({ status: 'busy' });
        const settings = await getSettings();
        const settingsBySectionKey = keyBy(settings, ({ section, key }) => `${section}.${key}`);
        set({
            openingHours: transformHours('open', settingsBySectionKey),
            closingHours: transformHours('close', settingsBySectionKey),
            settings: settings,
            status: 'idle',
        });
    },
}));

const transformHours = (type: 'open' | 'close', settingsBySectionKey: Record<string, Setting>) => ({
    mon: getValueOrDefault(settingsBySectionKey[`times.${type}-mon`]),
    tue: getValueOrDefault(settingsBySectionKey[`times.${type}-tue`]),
    wed: getValueOrDefault(settingsBySectionKey[`times.${type}-wed`]),
    thu: getValueOrDefault(settingsBySectionKey[`times.${type}-thu`]),
    fri: getValueOrDefault(settingsBySectionKey[`times.${type}-fri`]),
    sat: getValueOrDefault(settingsBySectionKey[`times.${type}-sat`]),
    sun: getValueOrDefault(settingsBySectionKey[`times.${type}-sun`]),
});

// intentional use of ternary conditional operator to catch empty string as well.
const getValueOrDefault = (setting: Setting) => (setting.value ? setting.value : setting.default);
