import React, { FC, useContext } from 'react';
import { DEFAULT_LOCALE } from './types/locale';
import { notImplemented } from './util/common';

const emptyState: AppState = {
    jwt: '',
    person: { personsSNO: 0, email: '', name: '', isSuperAdmin: 0, language: 'dk', locale: DEFAULT_LOCALE },
    showThemeSelector: true,
    theme: 'old',
    active: {
        employeeRole: 'employee',
        employeesSNO: 0,
        institutionName: '',
        institutionsSNO: 0,
        isMiniManager: 0,
    },
    normPeriod: undefined,
    normPeriodMetadata: undefined,
    institution: undefined,
    updateLanguage: () => notImplemented,
};

const AppContext = React.createContext(emptyState);

export const AppProvider: FC<{ value: AppState }> = ({ value, children }) => (
    <AppContext.Provider value={value}>{children}</AppContext.Provider>
);

export const useAppContext = () => useContext(AppContext);
