import log from '../logging/logging';
import { logout } from '../shared/logout';
import { HttpError } from '../types/errors/http-error';
import { InternalServerError } from '../types/errors/internal-server-error';
import { sleep } from '../util/common';
import { SessionStorage } from '../util/session-storage';
import { saveRequestToStackTrace } from '../util/stack-trace/save-request-to-stack-trace';

export interface RequestOptions {
    forceProduction?: boolean;
    useJwt?: boolean;
}

type Headers = {
    'Content-Type': 'application/json';
    'X-App-Version': string;
    'X-App-Support-Token'?: string;
    Authorization?: string;
};

const retryAttempts = 2;

// Not for public consumption
// Used by the public request() and requestWithType()
export async function requestBase<RequestParams = any>(
    jwt: string | undefined,
    path: string,
    requestParms: RequestParams,
    { forceProduction = false, useJwt = true }: RequestOptions = {}
): Promise<any> {
    const host = forceProduction ? process.env.REACT_APP_API_HOST_PROD : process.env.REACT_APP_API_HOST;

    const url = `${host}/${path}`;
    const body = JSON.stringify(requestParms);

    const headers: Headers = {
        'Content-Type': 'application/json',
        'X-App-Version': process.env.REACT_APP_VERSION ?? '',
    };

    if (useJwt && jwt) {
        headers.Authorization = `Bearer ${jwt}`;
    }

    const supportToken = SessionStorage.getItem('support-token');
    if (supportToken) {
        headers['X-App-Support-Token'] = supportToken;
    }

    const options = {
        method: 'POST',
        body,
        headers,
    };

    let attempt = 1;

    do {
        const result = await fetch(url, options);
        const response = await result.json();

        saveRequestToStackTrace(path, requestParms, response.responseCode, response.payload);

        switch (response.responseCode) {
            case 'OK':
                return { requestId: response.requestId, payload: response.payload };

            // Clear session to force the log in page to reappear
            case 'ACCESS_DENIED':
                log.error(`Access denied - The JWT is probably too old`, 'system:api');

                logout();
                window.location.replace('/');

                throw new Error('Blocking subsequent execution - The browser is about to reload');

            // @TODO
            //   - Extend into something clever
            //     Eg. telling the user that field X is not correct
            //     This *might* give the user an opportunity to fix the
            //     input that the FE validations allowed to slip through
            //
            //     It might also be used to completely skip FE validations
            //     and simply parse the reponse to have a single-source-of-truth
            //
            //     Example from dev tools
            //     Uncaught (in promise) Error: Request failed - Invalid arguments supplied: {"missingOrInvalidArguments":{"dateStart":{"dateMustNotBeWithinAnEmployment":{"rule":"The date must not be within an employment","supplied":"2022-01-01",...
            //
            case 'ARGUMENTS_INVALID':
                log.error(
                    `Request failed - Invalid arguments supplied: ${JSON.stringify(response.payload)}`,
                    `requestId:${response.requestId}`
                );
                throw new HttpError(
                    response.requestId,
                    'Request failed - Invalid arguments supplied: ' + JSON.stringify(response.payload),
                    response.payload
                );
            case 'NORM_TIME_OVERLAPPING_SAME_OR_HIGHER_LEVEL':
                log.error(
                    `Request failed - Invalid arguments supplied: ${JSON.stringify(response.payload)}`,
                    `requestId:${response.requestId}`
                );
                throw new HttpError(
                    response.requestId,
                    'Request failed - Invalid arguments supplied: ' + JSON.stringify(response.payload),
                    response.payload
                );
            case 'UNHANDLED_ERROR':
                // Retry on HTTP 500 with a slight pause
                if (attempt <= retryAttempts) {
                    await sleep(attempt * 1000);
                    attempt++;
                    continue;
                }

                log.error('Request failed - Internal Server Error - 500', `requestId:${response.requestId}`);
                throw new InternalServerError(response.requestId, 'Request failed - Server error - 500');
            default:
                log.error(`Request failed: ${path}`, `requestId:${response.requestId}`);
                throw new Error('Request failed: ' + path);
        }
    } while (true);
}
