import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { sendForgotPasswordEmail } from '../api/person';

interface Props {
    onBack: () => void;
}

export function ForgotPassword(props: Props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [screen, setScreen] = useState('send-reminder');
    const [email, setEmail] = useState('');

    async function onSubmit() {
        if (email !== '') {
            setLoading(true);
            try {
                const lowercaseEmail = email.toLowerCase();
                await sendForgotPasswordEmail(lowercaseEmail);
                setScreen('reminder-sent');
            } finally {
                setLoading(false);
            }
        }
    }

    return (
        <>
            {screen === 'send-reminder' && (
                <>
                    <h2>{t('forgot.header')}</h2>
                    <p>{t('forgot.message')}</p>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        <div className="row">
                            <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('forgot.field.email')}</Form.Label>
                                    <Form.Control type="text" name="email" onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                            </div>
                        </div>
                        <Button variant="primary" type="submit">
                            {t('forgot.ok')}
                            {loading && (
                                <>
                                    &nbsp;{' '}
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                </>
                            )}
                        </Button>{' '}
                        &nbsp;
                        <Button variant="secondary" type="button" onClick={props.onBack}>
                            {t('forgot.cancel')}
                        </Button>
                    </form>
                </>
            )}

            {screen === 'reminder-sent' && (
                <>
                    <h2>{t('forgot.sent.header')}</h2>
                    <p>{t('forgot.sent.message')}</p>
                    <Button variant="primary" type="button" onClick={props.onBack}>
                        {t('forgot.sent.back')}
                    </Button>
                </>
            )}
        </>
    );
}
