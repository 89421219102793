import { FC, useState } from 'react';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { processRequest } from '../../api/notifications';
import { RequestAccess } from '../../api/notifications.type';
import { useAppContext } from '../../app-context';
import { formatI18nMessageInPlainText } from '../../components/format-i18n';
import Spinner from '../../components/spinner';
import { RemoteStatus } from '../../types/remote';
import styles from '../notifications.module.css';
import { ItemProps } from './type';

const NotificationRequestAccess: FC<ItemProps<RequestAccess>> = ({
    notificationsSNO,
    data: requestData,
    afterRequestHandled,
}) => {
    const { t } = useTranslation();
    const state = useAppContext();
    const [isLoading, setIsLoading] = useState<RemoteStatus>('idle');

    const handleRequest = async (answer: 'accept' | 'deny') => {
        try {
            setIsLoading('busy');
            await processRequest(state.jwt, notificationsSNO, answer);
            setIsLoading('idle');
            afterRequestHandled?.(notificationsSNO);
        } catch {
            setIsLoading('error');
        }
    };

    return (
        <Card className={styles.item}>
            <Card.Body>
                <Row>
                    <Col>
                        <h4>{t('notification.request-access.title')}</h4>
                        <div className={styles['item-text']}>
                            {formatI18nMessageInPlainText(t('notification.request-access.body'), {
                                name: requestData.asker.name,
                                role: t('notification.request-access.role.' + requestData.asksFor.role),
                                duration: t('notification.request-access.duration.' + requestData.asksFor.duration),
                            })}
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center gap-4">
                        {isLoading === 'busy' && <Spinner />}
                        {isLoading !== 'busy' && (
                            <>
                                <Button variant="success" size="lg" onClick={() => handleRequest('accept')}>
                                    {t('notification.request-access.accept')}
                                </Button>
                                <Button variant="danger" size="lg" onClick={() => handleRequest('deny')}>
                                    {t('notification.request-access.deny')}
                                </Button>
                            </>
                        )}
                    </Col>
                </Row>
            </Card.Body>
            {isLoading === 'error' && (
                <Card.Footer>
                    <Alert variant="danger">{t('notification.request-access.error')}</Alert>
                </Card.Footer>
            )}
        </Card>
    );
};

export default NotificationRequestAccess;
