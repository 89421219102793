import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    onLogout: () => void;
}

export function NoInstitutions(props: Props) {
    const { t } = useTranslation();

    return (
        <>
            <div className="px-4 py-5 my-5 text-center">
                <h1 className="display-5 fw-bold">{t('no-institutions.header')}</h1>
                <p className="lead mb-4">{t('no-institutions.message')}</p>
                <Button variant="primary" size="lg" onClick={props.onLogout}>
                    {t('no-institutions.logout')}
                </Button>
            </div>
        </>
    );
}
