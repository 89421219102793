import { getJwt } from '../jwt';
import { NormTimeNotification, NormTimeNotifications } from './norm-time-notifications.type';
import { request, requestWithRuntype } from './request';

export async function normTimeNotificationsGetAllFromPeriod(
    normPeriodsSNO: number,
    dateStart: string,
    dateEnd: string
): Promise<NormTimeNotification[]> {
    const path = 'norm-time-notifications-get-all-from-period';
    return await requestWithRuntype(NormTimeNotifications, getJwt(), path, {
        normPeriodsSNO,
        dateStart,
        dateEnd,
    });
}

export async function setNormTimeNotificationHandled(normTimeNotificationsSNO: number, isHandled: boolean) {
    await request(getJwt(), 'norm-time-notifications-set-is-handled', {
        normTimeNotificationsSNO,
        isHandled: isHandled ? 1 : 0,
    });
}
