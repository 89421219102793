import { getJwt } from '../jwt';
import { formatToIsoDate } from '../util/datetime';
import { request } from './request';

export async function getAverageInstHoursPerDay(normWorkersSNO: number) {
    const endpoint = 'winning-time-get-average-inst-hours-per-day';
    return await request(getJwt(), endpoint, { normWorkersSNO });
}

export async function getWinningTimeTotalsFromPeriod(
    normPeriodsSNO: number,
    dateStart: Date,
    dateEnd: Date,
    normWorkersSNOs: number[]
) {
    const args = {
        normPeriodsSNO,
        dateStart: formatToIsoDate(dateStart),
        dateEnd: formatToIsoDate(dateEnd),
        normWorkersSNOs,
    };
    const endpoint = 'winning-time-get-totals-from-period';
    return await request(getJwt(), endpoint, args);
}
