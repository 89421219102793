import * as st from 'simple-runtypes';

const NormWorker = st.sloppyRecord({
    normWorkersSNO: st.number(),
    employeesSNO: st.number(),
    name: st.string(),
});

const TeamsWithWorker = st.sloppyRecord({
    normTeamsSNO: st.number(),
    name: st.string(),
    normWorkers: st.array(NormWorker),
});

export const TeamsWithWorkerResponse = st.sloppyRecord({
    teamsWithWorkers: st.array(TeamsWithWorker),
});

export type TeamsWithWorker = ReturnType<typeof TeamsWithWorker>;
