import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useAppContext } from '../app-context';
import { MappedNavigate } from '../hooks/use-mapped-navigation';
import { formatToIsoDate, formatToIsoTimeWithSeconds } from '../util/datetime';
import { SessionStorage } from '../util/session-storage';

const SupportStart: FC = () => {
    const { tokenFromUser } = useParams();

    const { institution, person } = useAppContext();
    const institutionName = institution?.institutionName ?? '';
    const personName = person.name;

    const supportToken = generateSupportToken(institutionName, personName, tokenFromUser);

    useEffect(() => {
        SessionStorage.setItem('support-token', supportToken);
    }, [supportToken]);

    return <MappedNavigate to="root" />;
};

export default SupportStart;

// localhost:3000/support/start
// localhost:3000/support/start/my-token
const generateSupportToken = (
    institutionName: string,
    personName: string,
    tokenFromUser: string | undefined
): string => {
    const date = new Date();

    const supportTokenRaw = [
        formatToIsoDate(date),
        formatToIsoTimeWithSeconds(date).replaceAll(':', '-'),
        institutionName,
        personName,
        uuid().substring(0, 6),
        tokenFromUser ?? '',
    ].join('#');

    const supportTokenUrlFriendly = makeUrlFriendly(supportTokenRaw).toLowerCase();

    return supportTokenUrlFriendly;
};

// Uses dash as delimiter between sections
// to make it possible to split them again afterwards
//
// The function is specific for this use-case
const makeUrlFriendly = (value: string): string => {
    return value
        .replaceAll(/[^\w#]/gi, '_')
        .replaceAll(/_+/g, '_')
        .replace(/#/g, '-')
        .replace(/-$/g, '');
};
