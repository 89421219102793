import { isNil } from 'lodash-es';
import { useState } from 'react';
import * as st from 'simple-runtypes';
import { useEffectOnce, useInterval } from 'usehooks-ts';
import { useAppContext } from '../app-context';
import { getNotificationComponentByType } from '../notifications/items/mapping';
import useNotificationStore from '../store/notification';
import { useWindowBlur, useWindowFocus } from './use-window-focus-blur';

// default delay of 1 minute
const DEFAULT_INTERVAL = 60 * 1000;

export const useNotificationCount = () => {
    const { institution } = useAppContext();
    const employeesSNO = institution?.employeesSNO;

    const [delay, setDelay] = useState<number | null>(DEFAULT_INTERVAL);

    const { notifications, fetchNotifications } = useNotificationStore();

    const refreshNotifications = () => {
        if (isNil(employeesSNO)) {
            return;
        }
        return fetchNotifications(employeesSNO);
    };

    // Trigger when component is mounted
    useEffectOnce(() => {
        refreshNotifications();
    });

    // Trigger when window is focused, restart timer
    useWindowFocus(() => {
        setDelay(DEFAULT_INTERVAL);
        refreshNotifications();
    });

    // Stop timer when window is out of focus
    useWindowBlur(() => {
        setDelay(null);
    });

    useInterval(() => {
        refreshNotifications();
    }, delay);

    if (!notifications) {
        return 0;
    }
    let validatedNotificationCount = 0;
    for (const notification of notifications) {
        const ComponentsWithType = getNotificationComponentByType(notification.type);
        if (!ComponentsWithType) {
            continue;
        }
        const [, runtype] = ComponentsWithType;
        const validation = st.use(runtype, notification.data);
        if (validation.ok) {
            validatedNotificationCount++;
        }
    }
    return validatedNotificationCount;
};
