import { createContext, FC, useContext, useMemo } from 'react';
import { createStore, StoreApi, useStore } from 'zustand';
import { NormTimeType } from '../../types/norm-time-types';

type ShiftCloningState = {
    mode: 'normal' | 'cloning';
    template: CloneTemplate | undefined;
};

type CloneTemplate = {
    title: string;
    start: Date;
    end: Date;
    fullDay: boolean;
    normTimeType: NormTimeType;
};

type ShiftCloningAction = {
    inCloningMode: () => boolean;
    setMode: (mode: 'normal' | 'cloning') => void;
    pickTemplate: (template: CloneTemplate) => void;
};

const initialState: ShiftCloningState = {
    mode: 'normal',
    template: undefined,
};

const Context = createContext<StoreApi<ShiftCloningState & ShiftCloningAction> | undefined>(undefined);

const ShiftCloningContext: FC = ({ children }) => {
    const store = useMemo(
        () =>
            createStore<ShiftCloningState & ShiftCloningAction>((set, get) => ({
                ...initialState,
                inCloningMode: () => get().mode === 'cloning',
                setMode: (mode) => {
                    if (mode === 'normal') {
                        set({ mode, template: undefined });
                    } else {
                        set({ mode });
                    }
                },
                pickTemplate: (template) => {
                    set({
                        template,
                    });
                },
            })),

        []
    );

    return <Context.Provider value={store}>{children}</Context.Provider>;
};

export default ShiftCloningContext;

type UseStoreParams<U> = Parameters<typeof useStore<StoreApi<ShiftCloningState & ShiftCloningAction>, U>>;
type Selector<U> = UseStoreParams<U>[1];
type Equality<U> = UseStoreParams<U>[2];

export const useShiftCloningStore = <U extends unknown = ShiftCloningState & ShiftCloningAction>(
    selector?: Selector<U>,
    equality?: Equality<U>
) => {
    const ctx = useContext(Context);

    if (!ctx) {
        throw new Error('Did you forget to enclose the component with <ShiftCloningContext/>?');
    }

    const fullState: any = (state: any) => state;

    return useStore(ctx, selector ?? fullState, equality);
};
