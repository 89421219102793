import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import packgeJson from '../../package.json';
import { useNotificationCount } from '../hooks/use-notification';
import { FcWithSubNode } from '../types/react';
import FaIcon from './fa-icon';

export interface MenuItemProps {
    label: string;
    route: string;
    icon: string;
    badge?: boolean;
}

const Notification: FC<MenuItemProps> = (props) => {
    const notificationCount = useNotificationCount();

    return <MenuItem {...props} badge={notificationCount > 0} />;
};

const MenuItem: FcWithSubNode<{ Notification: FC<MenuItemProps> }, MenuItemProps> = ({
    label,
    route,
    icon,
    badge = false,
}) => {
    const { t } = useTranslation();

    const isCurrentlyChosenMenuItem = getIsCurrentlyChosenMenuItem(route);

    return (
        <li className={cx('sidebar-items menu-li', { active: isCurrentlyChosenMenuItem })}>
            <Link to={route} className="sidebar-links">
                <div style={{ fontSize: '1rem', maxWidth: '10px', display: 'inline' }}>
                    <FaIcon icon={icon} fixedWidth={true} className={cx({ 'has-badge': !!badge })} />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>{t(label)}</span>
            </Link>
        </li>
    );
};

MenuItem.Notification = Notification;

export default MenuItem;

/**
 * Checks if a given module path is the currently
 * chosen one in the browser
 *
 * @param modulePath Path from the menu item config - Prefixed with a forward slash
 * @returns
 */
const getIsCurrentlyChosenMenuItem = (modulePath: string) => {
    const path = getUrlPath();

    const fullModulePath = getModulePathFull(modulePath);

    // The current path is the main module
    if (fullModulePath === path) {
        return true;
    }

    // the current path starts with the module path
    // and is this a sub page to the module
    if (path.startsWith(`${fullModulePath}/`)) {
        return true;
    }

    return false;
};

// Own function as window.* is a bit magic
// Can be overridden for unit tests
//
// @TODO
//   - Should query strings be removed?
//   - hash-anchors ?
const getUrlPath = () => {
    return window.location.pathname;
};

// The test environment has the GIT branch name
// as the first part after the domain in the URL
//   - https://test.www.aarsnorm.dk/my-branch/landing-page
//   - https://test.www.aarsnorm.dk/master/landing-page
//
// The prod and staging environments do not have that
//   - https://staging.www.aarsnorm.dk/landing-page
//   - https://ny.aarsnorm.dk/landing-page
const getModulePathFull = (modulePath: string) => {
    if (packgeJson.homepage) {
        return `/${packgeJson.homepage}${modulePath}`;
    }

    return modulePath;
};
