import * as st from 'simple-runtypes';
import { DateRuntype, DateTimeRuntype } from '../types/validations/date-runtype';
import { WinningTime } from './norm-time.type';
import { NormWorker } from './norm-workers.type';

export type NotificationTypes =
    | NotificationRequestAccess
    | NotificationUserLockedOut
    | NotificationShiftChange
    | NotificationShiftNoteCreated
    | NotificationTimeRequest
    | NotificationTimeRequestResult
    | NotificationPlanningAssistantRollout;

const Asker = st.sloppyRecord({
    personsSNO: st.number(),
    name: st.string(),
});
type Asker = ReturnType<typeof Asker>;

const AsksFor = st.sloppyRecord({
    role: st.stringLiteralUnion('manager', 'pedagogue', 'pedagogicalAssistant', 'substitute'),
    duration: st.stringLiteralUnion('1-hour', '3-hours', '1-day', '1-week', '1-month'),
});
type AsksFor = ReturnType<typeof AsksFor>;

const Institution = st.sloppyRecord({
    institutionsSNO: st.number(),
    name: st.optional(st.string()),
    employeesSNOManager: st.optional(st.number()),
});
type Institution = ReturnType<typeof Institution>;

export type Notification = {
    notificationsSNO: number;
    employeesSNO: number;
    completed: string;
    created: string;
} & NotificationTypes;

type NotificationRequestAccess = {
    type: 'support-request';
    data: RequestAccess;
};

export const RequestAccess = st.sloppyRecord({
    asker: Asker,
    asksFor: AsksFor,
    institution: Institution,
});

export type RequestAccess = ReturnType<typeof RequestAccess>;

type NotificationUserLockedOut = {
    type: 'user-is-locked-out';
    data: UserLockedOut;
};

type NotificationPlanningAssistantRollout = {
    type: 'planning-assistant-roll-out';
    data: PlanningAssistantRollout;
};

export const PlanningAssistantRollout = st.sloppyRecord({
    template: st.sloppyRecord({
        planningAssistantTemplatesSNO: st.number(),
        normPeriodsSNO: st.number(),
        name: st.string(),
        description: st.string(),
    }),
    teamsAndNormWorkers: st.array(
        st.sloppyRecord({
            team: st.sloppyRecord({
                teamName: st.string(),
                normTeamsSNO: st.number(),
            }),
            normWorkers: st.array(
                st.sloppyRecord({
                    normWorkersSNO: st.number(),
                    name: st.string(),
                    employeeRole: st.string(),
                })
            ),
        })
    ),
    dateStart: st.string(),
    dateEnd: st.string(),
});

export type PlanningAssistantRollout = ReturnType<typeof PlanningAssistantRollout>;

export const UserLockedOut = st.sloppyRecord({
    person: Asker,
    institutions: st.array(Institution),
});
export type UserLockedOut = ReturnType<typeof UserLockedOut>;

type NotificationShiftChange = {
    type: 'norm-worker-shift-change';
    data: ShiftChangeData;
};
type NotificationShiftNoteCreated = {
    type: 'norm-time-notes-create';
    data: ShiftNoteCreatedData;
};

export const ShiftChangeData = st.sloppyRecord({
    winningTime: st.array(WinningTime),
    date: DateRuntype,
});

export type ShiftChangeData = ReturnType<typeof ShiftChangeData>;

const TimeType = st.sloppyRecord({
    normTimeTypesSNO: st.number(),
    type: st.string(),
    level: st.number(),
    name: st.string(),
    nameShort: st.string(),
    timeUnit: st.string(),
    colorCodeBackground: st.string(),
    colorCodeText: st.string(),
    howAWholeDayIsCalculated: st.string(),
    isASpendingType: st.number(), // Assuming this should be a boolean represented as 0 or 1, you might want to add a custom validator
    howNetSpendingIsDerived: st.string(),
    categoryType: st.string(),
    isWorking: st.number(), // Same note as isASpendingType
    isVacation: st.number(), // Same note as isASpendingType
    isSick: st.number(), // Same note as isASpendingType
});

export const ShiftNoteCreatedData = st.sloppyRecord({
    timeStart: st.string(),
    timeEnd: st.string(),
    timeType: TimeType,
    note: st.string(),
});

export type ShiftNoteCreatedData = ReturnType<typeof ShiftNoteCreatedData>;

type NotificationTimeRequest = {
    type: 'norm-worker-time-request';
    data: NotificationTimeRequestData;
};

export const NotificationTimeRequestData = st.sloppyRecord({
    normWorker: NormWorker,
    type: st.string(),
});
export type NotificationTimeRequestData = ReturnType<typeof NotificationTimeRequestData>;

type NotificationTimeRequestResult = {
    type:
        | 'time-request-accept-to-employee-create'
        | 'time-request-accept-to-employee-update'
        | 'time-request-accept-to-employee-delete'
        | 'time-request-deny-to-employee-create'
        | 'time-request-deny-to-employee-update'
        | 'time-request-deny-to-employee-delete';
    data: NotificationTimeRequestResultData;
};

const TimeRange = st.sloppyRecord({ timeStart: DateTimeRuntype, timeEnd: DateTimeRuntype });

const NotificationTimeRequestType = st.union(
    st.sloppyRecord({
        askedFor: st.literal('shift-new'),
        shiftSrc: st.null(),
        shiftDst: TimeRange,
    }),
    st.sloppyRecord({
        askedFor: st.literal('shift-update'),
        shiftSrc: TimeRange,
        shiftDst: TimeRange,
    }),
    st.sloppyRecord({
        askedFor: st.literal('shift-delete'),
        shiftSrc: TimeRange,
        shiftDst: st.null(),
    })
);

export const NotificationTimeRequestResultData = st.intersection(
    st.sloppyRecord({
        employee: st.sloppyRecord({
            normWorkersSNO: st.number(),
            personsSNO: st.number(),
            name: st.string(),
        }),
        result: st.stringLiteralUnion('accept', 'deny'),
        description: st.optional(st.string()),
    }),
    NotificationTimeRequestType
);
export type NotificationTimeRequestResultData = ReturnType<typeof NotificationTimeRequestResultData>;
