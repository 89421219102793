// @Stephen - Right location for this file?

let env = '';

export const setEnvironment = (value: string): void => {
    env = value;
};

export const getEnvironment = (): string => {
    return env;
};
