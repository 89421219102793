import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export function Footer() {
    const { t } = useTranslation();
    return (
        <footer className="footer mt-auto py-3">
            <Container className="text-center">
                {t('footer.copyright')} &nbsp;
                <a href={t('footer.url')}>{t('footer.company-name')}</a> &nbsp; &bull; &nbsp;
                <a href={'mailto:' + t('footer.email')}>{t('footer.email')}</a>
            </Container>
        </footer>
    );
}
