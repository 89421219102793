import { useEffect, useRef } from 'react';

/**
 * Utility function that returns a ref object that holds
 * - true if component is mounted
 * - false if component has been unmounted
 * @returns React ref object
 */
const useMounted = () => {
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    return mounted;
};

export default useMounted;
