import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { parseIsoDateTime } from '../util/datetime';

interface PeriodState {
    period: Period | undefined;
    selectedFirstDayOfWeek: Date | undefined;
}

interface PeriodAction {
    setPeriod: (period: Period | undefined) => void;
    // used in <WeekNavigator />
    setSelectedFirstDayOfWeek: (selectedFirstDayOfWeek: Date | undefined) => void;
    clear: () => void;
    fullClear: () => void;
}

const storage = window.sessionStorage;

const initial: PeriodState = {
    period: undefined,
    // used in <WeekNavigator />
    selectedFirstDayOfWeek: undefined,
};

const usePeriodStore = create<PeriodState & PeriodAction, [['zustand/persist', PeriodState]]>(
    persist(
        (set) => ({
            ...initial,
            setPeriod: (period) => {
                set({ period, selectedFirstDayOfWeek: undefined });
            },
            setSelectedFirstDayOfWeek: (val) => {
                set({ selectedFirstDayOfWeek: val });
            },
            clear: () => set(initial),
            fullClear: () => set({ period: undefined, setSelectedFirstDayOfWeek: undefined }),
        }),
        {
            name: 'norm-period',
            storage: {
                getItem: (name) => {
                    let value;
                    if (!storage || !(value = storage.getItem(name))) {
                        return null;
                    }

                    try {
                        return JSON.parse(value, function (key, value) {
                            switch (key) {
                                case 'timeStart':
                                    this.start = parseIsoDateTime(value);
                                    break;
                                case 'timeEnd':
                                    this.end = parseIsoDateTime(value);
                                    break;
                                case 'selectedFirstDayOfWeek':
                                    const date = new Date(value);
                                    return !isNaN(+date) ? date : undefined;
                            }

                            return value;
                        });
                    } catch (error) {
                        console.warn(error);
                        return null;
                    }
                },
                setItem: (name, value) => {
                    if (!storage) {
                        return;
                    }

                    if (!value || !value.state.period) {
                        storage.removeItem(name);
                        return;
                    }

                    // Do not serialise start & end which are of Date object, just skip
                    const { start: _start, end: _end, ...period } = value.state.period;

                    storage.setItem(
                        name,
                        JSON.stringify({ state: { ...value.state, period: { ...period } } }, (key, value) => {
                            switch (key) {
                                case 'selectedFirstDayOfWeek':
                                    if (!isNaN(+new Date(value))) {
                                        return new Date(value).getTime();
                                    } else {
                                        return undefined;
                                    }
                            }
                            return value;
                        })
                    );
                },
                removeItem: (name) => {
                    storage.removeItem(name);
                },
            },
        }
    )
);

export default usePeriodStore;
