import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FaIcon from '../components/fa-icon';
import breadcrumb from '../config/breadcrumbs.json';
import routes from '../config/routes.json';
import useMappedNavigation from '../hooks/use-mapped-navigation';

export function AutoBreadcrumb() {
    const { t } = useTranslation();
    const navigate = useMappedNavigation();

    const conf: {
        [key: string]: {
            urlKey: string | null;
            langKey: string;
        }[];
    } = breadcrumb;

    function getCurrentPath() {
        let url = window.location.pathname;
        url = url.replace(/^\/admin/, '');
        url = url.replaceAll(/\/\d+/gi, '/%s');
        url = url.replace(/^\//, '');
        url = url.replace(/\/$/, '');

        return url;
    }

    const page = getCurrentPath();

    if (conf[page]) {
        return (
            <Breadcrumb>
                <Breadcrumb.Item title={t('menu.home')} key="breadcrumb-home" onClick={() => navigate('landing-page')}>
                    <FaIcon icon="home" />
                </Breadcrumb.Item>
                {conf[page].map((r) => {
                    const { urlKey, langKey } = r;
                    if (urlKey) {
                        let url = urlKey as keyof typeof routes;
                        return (
                            <Breadcrumb.Item key={'breadcrumb-' + langKey} onClick={() => navigate(url)}>
                                {t(langKey)}
                            </Breadcrumb.Item>
                        );
                    }

                    return (
                        <Breadcrumb.Item key={'breadcrumb-' + langKey} active>
                            {t(langKey)}
                        </Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        );
    } else {
        return <></>;
    }
}
