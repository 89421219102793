/* eslint-disable @typescript-eslint/no-redeclare */
import { Boolean, Null, Number, Record, Static, String } from 'runtypes';

export const Jwt = Record({
    person: Record({
        personsSNO: Number,
        email: String,
        passwordIsOtp: Number,
        name: String,
        isSuperAdmin: Number,
        language: String,
        showDetailedErrorMessages: Number,
    }).optional(),
    institution: Record({
        institutionsSNO: Number,
        employeesSNO: Number,
        employeeRole: String,
        institutionName: String,
        isActive: Boolean,
    })
        .Or(Null)
        .optional(),
    normPeriod: Record({
        normPeriodsSNOCurrent: Number.Or(Null),
        normWorkersSNOOwn: Number.Or(Null),
    }).optional(),
});

export type Jwt = Static<typeof Jwt>;
