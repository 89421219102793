import { getJwt } from '../jwt';
import { TeamWithWorkers } from '../types/planning-asst';
import { request, requestWithRuntype } from './request';
import { TeamsWithWorkerResponse } from './teams.type';

// jwt to accept undefined for backward compatibility - the parameter to be removed
export async function getAllTeams(jwt: string | undefined, normPeriodsSNO: number): Promise<Team[]> {
    return await request(jwt ?? getJwt(), 'norm-teams-get-all', { normPeriodsSNO });
}
export async function getAllTeamsWithNormWorkersNormTime(
    jwt: string | undefined,
    normPeriodsSNO: number
): Promise<TeamWithWorkers[]> {
    return await request(jwt ?? getJwt(), 'norm-teams-get-all-with-norm-workers-from-norm-time', { normPeriodsSNO });
}

export async function getAllTeamsWithNormWorkersNormTimePrimaryTeam(jwt: string | undefined, normPeriodsSNO: number) {
    return await request(jwt ?? getJwt(), 'norm-teams-get-all-with-norm-workers-from-primary-team', { normPeriodsSNO });
}

export async function getAllTeamsWithNormWorkersFromPrimaryTeam(normPeriodsSNO: number) {
    return await requestWithRuntype(
        TeamsWithWorkerResponse,
        getJwt(),
        'norm-teams-get-all-with-norm-workers-from-primary-team',
        { normPeriodsSNO }
    );
}

export async function createTeam(jwt: string, normPeriodsSNO: number, name: string) {
    await request(jwt, 'norm-team-create', { name, normPeriodsSNO });
}

export async function deleteTeam(jwt: string, normTeamsSNO: number) {
    await request(jwt, 'norm-team-delete', { normTeamsSNO });
}

export async function renameTeam(jwt: string, normTeamsSNO: number, name: string) {
    await request(jwt, 'norm-team-name-update', { normTeamsSNO, name });
}

export async function moveTeam(jwt: string, normTeamsSNO: number, direction: 'up' | 'down') {
    const endpoint = 'norm-team-move';
    await request(jwt, endpoint, { direction, normTeamsSNO });
}

export async function normTimeMoveAllShiftsToTeamInPeriod(
    jwt: string,
    dateTimeStart: string,
    dateTimeEnd: string,
    normWorkersSNO: number,
    normTeamsSNOSrc: number,
    normTeamsSNODst: number
) {
    await request(jwt, 'norm-time-move-all-shifts-to-team-in-period', {
        dateTimeStart,
        dateTimeEnd,
        normWorkersSNO,
        normTeamsSNOSrc,
        normTeamsSNODst,
    });
}
