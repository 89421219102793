import { Failure, Runtype } from 'runtypes';

export class RuntypeError<R> extends Error {
    public readonly originalMessage: string | undefined;

    constructor(private type: Runtype<R>, private failedValidation: Failure, private value: any, message?: string) {
        super(
            ((type: Runtype<R>, validation: Failure, value: any, message?: string) => {
                const errorMessage = `Code: ${validation.code}

${validation.details ? `Details: ${JSON.stringify(validation.details, null, 2)}` : ''}

Expected: ${type.reflect}

Actual: ${JSON.stringify(value, null, 2)}
`;

                return message ? `${message}\n\n${errorMessage}` : errorMessage;
            })(type, failedValidation, value, message)
        );

        this.originalMessage = message;
    }

    public get expected() {
        return this.type;
    }

    public get actual(): any {
        return this.value;
    }

    public get formattedActual() {
        return JSON.stringify(this.actual, null, 2);
    }

    public get failure() {
        return this.failedValidation;
    }
}
