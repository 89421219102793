import {
    addDays,
    differenceInHours,
    differenceInMinutes,
    eachWeekOfInterval as dateFnsEachWeekOfInterval,
    endOfWeek as dateFnsEndOfWeek,
    format,
    getDay,
    Interval,
    parse,
    startOfDay,
    startOfToday,
    startOfWeek as dateFnsStartOfWeek,
} from 'date-fns';
import { da, enGB } from 'date-fns/locale';
import { Day } from '../types/date';
import { DEFAULT_LOCALE, SystemLocale } from '../types/locale';

const LOCALE_MAP: Record<SystemLocale, Locale> = {
    da_DK: da,
    en_GB: enGB,
};

const SYSTEM_ISO_DATE = 'yyyy-MM-dd';
const SYSTEM_ISO_MONTH_YEAR = 'yyyy-MM';
const SYSTEM_ISO_DATE_TIME = 'yyyy-MM-dd HH:mm:ss';
const SYSTEM_ISO_DATE_TIME_WITHOUT_SECONDS = 'yyyy-MM-dd HH:mm';
const SYSTEM_ISO_TIME = 'HH:mm';
const SYSTEM_ISO_TIME_WITH_SECONDS = 'HH:mm:ss';
const NORM_TIME_START = 'yyyy-MM-dd HH:mm:00';
const NORM_TIME_END = 'yyyy-MM-dd HH:mm:59';
const CALENDAR_DATE_TIME = "yyyy-MM-dd'T'HH:mm";
const SHORT_DATE = 'd. MMM';
const SHORT_DATE_GB = 'd MMM';
const LONG_DATE = 'd MMM yyyy';
const DISPLAY_TIME = 'H:mm';
const DISPLAY_DATE = 'dd-MM-yyyy';
const DISPLAY_MONTH = 'MMMM';
const DISPLAY_MONTH_YEAR = 'MMMM yyyy';
const DISPLAY_DAY_OF_WEEK = 'EEEE';

const DAY_OF_WEEK_LABELS = ['day.sun', 'day.mon', 'day.tue', 'day.wed', 'day.thu', 'day.fri', 'day.sat'];

// date-fns functions with default

const optStartsOnMonday: Parameters<typeof dateFnsStartOfWeek>[1] = {
    // In European region, a week always starts on Monday (1)
    weekStartsOn: 1,
};

export const startOfWeek = (date: Date | number) => dateFnsStartOfWeek(date, optStartsOnMonday);

export const endOfWeek = (date: Date | number) => dateFnsEndOfWeek(date, optStartsOnMonday);

export const eachWeekOfInterval = (interval: Interval) => dateFnsEachWeekOfInterval(interval, optStartsOnMonday);

// For internal use

export const getDateFnsLocaleFromSystemLocale = (systemLocale: SystemLocale) => LOCALE_MAP[systemLocale];

// Date util

export const parseIsoMonthYear = (dateStr: string) => parse(dateStr, SYSTEM_ISO_MONTH_YEAR, new Date());

export const parseIsoDate = (dateStr: string) => parse(dateStr, SYSTEM_ISO_DATE, new Date());

export const parseIsoDateTime = (dateStr: string, excludeSeconds = false) =>
    parse(dateStr, excludeSeconds ? SYSTEM_ISO_DATE_TIME_WITHOUT_SECONDS : SYSTEM_ISO_DATE_TIME, new Date());

export const parseCalendarDate = (dateStr: string) => parse(dateStr, CALENDAR_DATE_TIME, new Date());

export const formatToIsoMonthYear = (date: Date | number) => format(date, SYSTEM_ISO_MONTH_YEAR);

export const formatToIsoDate = (date: Date | number) => format(date, SYSTEM_ISO_DATE);

export const formatToIsoDateTime = (date: Date | number) => format(date, SYSTEM_ISO_DATE_TIME);

export const formatToNormTimeStart = (date: Date | number) => format(date, NORM_TIME_START);
export const formatToNormTimeEnd = (date: Date | number) => format(date, NORM_TIME_END);

export const formatToCalendarDate = (date: Date | number | undefined) =>
    date ? format(date, CALENDAR_DATE_TIME) : undefined;

export const formatShortDate = (date: Date | number, locale: SystemLocale = 'da_DK') =>
    format(date, locale === 'da_DK' ? SHORT_DATE : SHORT_DATE_GB, { locale: LOCALE_MAP[locale] });

export const formatLongDate = (date: Date | number, locale: SystemLocale = 'da_DK') =>
    format(date, LONG_DATE, { locale: LOCALE_MAP[locale] });

export const formatToMonth = (date: Date | number, locale: SystemLocale = DEFAULT_LOCALE) =>
    format(date, DISPLAY_MONTH, { locale: LOCALE_MAP[locale] });

export const formatToDayOfWeek = (date: Date | number, locale: SystemLocale = DEFAULT_LOCALE) =>
    format(date, DISPLAY_DAY_OF_WEEK, { locale: LOCALE_MAP[locale] });

export const formatToMonthYear = (date: Date | number) => format(date, DISPLAY_MONTH_YEAR, { locale: da });

export const formatToDate = (date: Date | number) => format(date, DISPLAY_DATE);

export const formatToIsoTime = (date: Date | number) => format(date, SYSTEM_ISO_TIME);

export const formatToIsoTimeWithSeconds = (date: Date | number) => format(date, SYSTEM_ISO_TIME_WITH_SECONDS);

export const formatToTime = (date: Date | number) => format(date, DISPLAY_TIME);

export const isStartOfDay = (date: Date | number) => differenceInMinutes(startOfDay(date), date) === 0;

export const isSameDay = (start: Date, end: Date) =>
    formatToIsoDate(start) === formatToIsoDate(end) || (isStartOfDay(end) && differenceInHours(end, start) <= 24);

export const getDayName = (date: Date | number) => Day.values[getDay(date)];

export const getDayFromDayName = (day: Day) => Day.values.indexOf(day);

export const getDateFromDay = (refDate: Date | number, day: Day | (0 | 1 | 2 | 3 | 4 | 5 | 6)) => {
    const dayInNum = typeof day === 'number' ? day : getDayFromDayName(day);

    // Make Sunday the last day of week (having Sunday being 7 instead of 0)
    const mondayBasedDayInNum = dayInNum === 0 ? 7 : dayInNum;
    const mondayBasedRefDateDay = getDay(refDate) === 0 ? 7 : getDay(refDate);

    const diff = mondayBasedDayInNum - mondayBasedRefDateDay;

    return addDays(startOfDay(refDate), diff);
};

/**
 * A week starts from Monday and ends on Sunday
 * @param date The reference date
 * @param week any date for a given week
 * @returns
 */
export const isDateWithinWeek = (date: Date, week: Date): boolean => {
    const monday = getDay(week) === 1 ? startOfDay(week) : getDateFromDay(week, 'mon');

    return +date >= +monday && +date < +addDays(monday, 7);
};

export const getDayOfWeekLabel = (dayOfWeek: number, short = false) =>
    `${DAY_OF_WEEK_LABELS[dayOfWeek]}${short ? '.short' : ''}`;

export const timeUtil = {
    isBefore: (base: string, compared: Date | number) => {
        return base > format(compared, SYSTEM_ISO_TIME);
    },
    isOnOrBefore: (base: string, compared: Date | number) => {
        return base >= format(compared, SYSTEM_ISO_TIME);
    },
    isAfter: (base: string, compared: Date | number) => {
        return base < format(compared, SYSTEM_ISO_TIME);
    },
    isOnOrAfter: (base: string, compared: Date | number) => {
        return base <= format(compared, SYSTEM_ISO_TIME);
    },
    /**
     * Return a given ISO time in milliseconds
     * @param isoTime In 'HH:mm' or 'HH:mm:ss' format, e.g. 07:30 or 13:20:59
     */
    toMillis: (isoTime: string) => {
        if (!/^([0|1][0-9]|2[0-4]):[0-5][0-9](:[0-5][0-9])?$/.test(isoTime)) {
            throw new Error('Invalid ISO time format - it has to be in HH:mm');
        }

        const [hours, minutes, seconds] = isoTime.split(':');

        return (
            parseInt(hours, 10) * 60 * 60 * 1000 +
            parseInt(minutes, 10) * 60 * 1000 +
            (!isNaN(+seconds) ? parseInt(seconds, 10) * 1000 : 0)
        );
    },

    /**
     * Take a string of time value and return number of minutes from 0:00
     * @param input Time value, allowed format
     * * 0-24 round number
     * * 07:30 or 9:45 or 13:33 or 24:00
     * * 07.30 or 9.45 or 13.33 or 24.00
     * * 0730 or 945 or 1333 or 2400
     * @returns Number of minutes from 0:00
     */
    stringToMinutes: (input: string) => {
        // if it's a round number between 0 and 24, just add as hour
        if (/^\d+$/.test(input) && parseInt(input, 10) <= 24) {
            return parseInt(input, 10) * 60;
        }

        // 1 day
        if (/^24[:.]?00$/.test(input)) {
            return 24 * 60;
        }

        // In proper time format - 300 or 03:00 or 03.00 or 3:00 or 03.00 format
        const match = /^([0-1]?\d|2[0-3])[:.]?([0-5]\d)$/.exec(input);
        if (match) {
            const [_, hour, minute] = match;

            return Number(hour) * 60 + Number(minute);
        }

        throw new Error(`'${input}' is not a valid time of day`);
    },

    millisFromStartOfDay: (inputTime: Date | number): number => {
        const time = new Date(inputTime);
        return +time - +startOfDay(time);
    },

    /**
     * Take in number of minutes from start of day and the time in string (ISO format).
     *
     * @param timeInMinutes e.g. 180 (mins)
     * @returns 03:00 for 180 minutes
     */
    timeInMinutesToIsoString: (timeInMinutes: number): string => {
        const time = new Date(+startOfToday() + timeInMinutes * 60 * 1000);
        return formatToIsoTime(time);
    },
};

export function roundDateToNearestMinute(date: Date) {
    const ms = 1000 * 60;

    return new Date(Math.round(date.getTime() / ms) * ms);
}

export function addOneMinuteToStringTime(time: string): string {
    const someDate = new Date('1970-01-01T' + time + 'Z'); //We only care about the timespan
    someDate.setMinutes(someDate.getMinutes() + 1);
    let hours = someDate.getUTCHours().toString().padStart(2, '0');
    let minutes = someDate.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}
export function secondsToDate(seconds: number) {
    return new Date(0, 0, 0, 0, 0, seconds);
}

export function formatAndRoundDate(date: Date, formatString: string) {
    const roundedDate = roundDateToNearestMinute(date);

    return format(roundedDate, formatString);
}

export const getDateFnsLocale = (locale: SystemLocale) => LOCALE_MAP[locale];
