/* eslint-disable @typescript-eslint/no-redeclare */
import { Array, Number, Record, Static, String } from 'runtypes';
import { Jwt } from '../types/errors/jwt';

export const AppLog = Record({
    logFeSNO: Number,
    message: String,
    jwtData: Jwt.optional(),
    tags: Array(String),
    stackTrace: String,
    created: String,
});

export type AppLog = Static<typeof AppLog>;

export const AppLogs = Array(AppLog);

export type AppLogs = Static<typeof AppLogs>;
