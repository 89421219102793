import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

export function Protected({ children }: { children: JSX.Element }) {
    const jwt = getActiveJWT();
    const personData = getActivePerson();
    const location = useLocation();
    if (jwt === null || personData === null) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
}

const getActiveJWT = () => localStorage.getItem('jwt') || sessionStorage.getItem('jwt');
const getActivePerson = () => localStorage.getItem('person') || sessionStorage.getItem('person');
