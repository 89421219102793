import { FC } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { notificationSetCompleted } from '../../api/notifications';
import { PlanningAssistantRollout } from '../../api/notifications.type';
import { useAppContext } from '../../app-context';
import { formatI18nMessage } from '../../components/format-i18n';
import useMappedNavigation from '../../hooks/use-mapped-navigation';
import { dateFormat } from '../../util/dateformat';
import styles from '../notifications.module.css';
import { ItemProps } from './type';

const NotificationPlanningAssistantRollout: FC<ItemProps<PlanningAssistantRollout>> = ({
    notificationsSNO,
    data: rolloutData,
    beforeRequestHandled,
    afterRequestHandled,
}) => {
    const { t } = useTranslation();
    const state = useAppContext();
    const navigate = useMappedNavigation();

    const handleOK = async () => {
        beforeRequestHandled?.(notificationsSNO);
        await notificationSetCompleted(state.jwt, notificationsSNO);
        afterRequestHandled?.(notificationsSNO);
    };

    return (
        <Card className={styles.item}>
            <Card.Body>
                <Row>
                    <Col>
                        <h4>{t('notification.planning-assistant-rollout.title')}</h4>
                        <div className={styles['item-text']}>
                            {formatI18nMessage(t('notification.planning-assistant-rollout.body'), {
                                name: <b>{rolloutData.template.name}</b>,
                                periodStart: (
                                    <b>{dateFormat(rolloutData.dateStart, 'day month year', state.person.locale)}</b>
                                ),
                                periodEnd: (
                                    <b>{dateFormat(rolloutData.dateEnd, 'day month year', state.person.locale)}</b>
                                ),
                            })}
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center gap-4">
                        <Button
                            as="a"
                            variant="primary"
                            size="lg"
                            onClick={() =>
                                navigate({
                                    pathname: 'workplan',
                                    hash: `${rolloutData.dateStart}`,
                                })
                            }
                        >
                            {t('notification.time-request.view')}
                        </Button>
                        <Button variant="success" size="lg" onClick={handleOK}>
                            {t('notification.time-request.ok')}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default NotificationPlanningAssistantRollout;
