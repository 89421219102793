import { Literal, Static, Union } from 'runtypes';
import { EmployeeRole } from '../types/employee-role';

export const UserRole = Union(EmployeeRole, Literal('superAdmin'), Literal('miniManager'));

export type UserRole = Static<typeof UserRole>;

export const userHasMenuItemRole = (userRoles: string[], menuItemRoles: string[]) => {
    if (!menuItemRoles.length) {
        return true;
    }

    if (!menuItemRoles.some((role) => userRoles.includes(role))) {
        return false;
    }

    return true;
};

export const getUserRoles = (isSuperAdmin: number, institution: AppState['institution']) => {
    if (!institution) {
        return [];
    }
    const userRole = institution.employeeRole;

    const userRoles = [userRole];

    if (isSuperAdmin === 1) {
        userRoles.push('superAdmin');
    }

    if (institution!.isMiniManager === 1) {
        userRoles.push('miniManager');
    }

    return userRoles;
};

export const level1Users = ['pedagogue', 'pedagogicalAssistant', 'substitute'];
export const level2Users = ['manager', 'miniManager'];
export const level3Users = ['superAdmin'];
