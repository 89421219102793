import { localeDa, setOptions } from '@mobiscroll/react';

const initGlobalSettings = () => {
    initMobiscrollTimelineSettings();
};

export default initGlobalSettings;

const initMobiscrollTimelineSettings = () =>
    setOptions({
        locale: localeDa,
        theme: 'material',
        themeVariant: 'light',
    });
