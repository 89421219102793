import { FunctionComponent, useEffect, useState } from 'react';
import useMounted from '../hooks/use-mounted';
import { Page } from './page';
import Spinner from './spinner';

interface Props {
    delay?: number;
}

const LazyLoadingFallback: FunctionComponent<Props> = ({ delay = 1000 }) => {
    const [show, setShow] = useState(false);
    const mounted = useMounted();

    useEffect(() => {
        setTimeout(() => {
            if (mounted.current) {
                setShow(true);
            }
        }, delay);
    }, [delay, mounted]);

    return <Page>{show ? <Spinner center /> : null}</Page>;
};

export default LazyLoadingFallback;
