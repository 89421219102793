import * as st from 'simple-runtypes';

export const NormWorkerRemainingHours = st.sloppyRecord({
    normWorkersSNO: st.number(),
    employeeRole: st.string(),
    remainingInstitutionHours: st.nullOr(st.number()),
    remainingInstitutionHoursHuman: st.nullOr(st.string()),
});

export type NormWorkerRemainingHours = ReturnType<typeof NormWorkerRemainingHours>;

export const NormWorkerActualAverageInstHours = st.sloppyRecord({
    normWorkersSNO: st.number(),
    averageInstitutionHoursPerDay: st.string(),
});

export type NormWorkerActualAverageInstHours = ReturnType<typeof NormWorkerActualAverageInstHours>;
