import { create } from 'zustand';
import { getAllNotifications } from '../api/notifications';
import { Notification } from '../api/notifications.type';

interface NotificationStore {
    notifications: Notification[] | undefined;
    fetchNotifications: (employeesSNO: number) => Promise<Notification[]>;
    optimisticUpdate: (notificationsSNO: number) => void;
}

const useNotificationStore = create<NotificationStore>((set) => ({
    notifications: undefined,
    fetchNotifications: async () => {
        const notifications = await getAllNotifications();
        set({ notifications });
        return notifications;
    },
    optimisticUpdate: (notificationsSNO: number) => {
        set((current) => ({
            notifications: current.notifications?.filter(
                (notification) => notification.notificationsSNO !== notificationsSNO
            ),
        }));
    },
}));

export default useNotificationStore;
