export function getLastDayOfWeek(date: Date) {
    const dayOfWeek = date.getDay();

    // For the date system, sunday = 0 for getDay() and saturday = 6
    // we adjust for this so we correctly  get sunday as the last
    // day of the week
    const diff = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;

    const lastDayOfWeek = new Date(date);
    lastDayOfWeek.setDate(date.getDate() + diff);
    return lastDayOfWeek;
}
