import { TFunction } from 'react-i18next';
import { formatI18nMessageInPlainText } from '../components/format-i18n';

export const MINIMUM_PASSWORD_LENGTH = 8;
export const MINIMUM_UPPERCASE_LETTERS = 1;
export const MINIMUM_LOWERCASE_LETTERS = 1;
export const MINIMUM_DIGITS = 0;

type Check = 'passed' | 'failed';

export interface PasswordCriteria {
    empty: {
        check: Check;
        message: string;
    };
    length: {
        check: Check;
        message: string;
    };
    lowercase: {
        check: Check;
        message: string;
    };
    uppercase: {
        check: Check;
        message: string;
    };
}

function countUppercase(a: string): number {
    let result = 0;
    const b = a.toLocaleLowerCase();
    for (let i = 0; i < a.length; ++i) {
        result += a[i] !== b[i] ? 1 : 0;
    }
    return result;
}

function countLowercase(a: string): number {
    let result = 0;
    const b = a.toLocaleUpperCase();
    for (let i = 0; i < a.length; ++i) {
        result += a[i] !== b[i] ? 1 : 0;
    }
    return result;
}

export const validatePassword = (password: string, t: TFunction<'translation', undefined>): PasswordCriteria => {
    const checklist: PasswordCriteria = {
        empty: { check: 'failed', message: formatErrorMessage(t, 'set-password.criteria.required') },
        length: {
            check: 'failed',
            message: formatErrorMessage(t, 'set-password.criteria.length', {
                length: MINIMUM_PASSWORD_LENGTH.toString(),
            }),
        },
        lowercase: {
            check: 'failed',
            message: formatErrorMessage(t, 'set-password.criteria.lowercase', {
                length: MINIMUM_UPPERCASE_LETTERS.toString(),
            }),
        },
        uppercase: {
            check: 'failed',
            message: formatErrorMessage(t, 'set-password.criteria.uppercase', {
                length: MINIMUM_LOWERCASE_LETTERS.toString(),
            }),
        },
    };

    if (password.length > 0) {
        checklist.empty.check = 'passed';
    }

    if (password.length >= MINIMUM_PASSWORD_LENGTH) {
        checklist.length.check = 'passed';
    }

    if (countLowercase(password) >= MINIMUM_LOWERCASE_LETTERS) {
        checklist.lowercase.check = 'passed';
    }

    if (countUppercase(password) >= MINIMUM_UPPERCASE_LETTERS) {
        checklist.uppercase.check = 'passed';
    }

    return checklist;
};

const formatErrorMessage = (
    t: TFunction<'translation', undefined>,
    key: string,
    substitutions?: Record<string, string>
) => (substitutions ? formatI18nMessageInPlainText(t(key), substitutions) : t(key));
