import { excludedStackTracePaths } from '../../config/excluded-stack-trace-paths';
import { SessionStorage } from '../session-storage';

export function saveRequestToStackTrace(path: string, requestParams: any, responseCode: string, payload: string) {
    if (excludedStackTracePaths.includes(path)) {
        return;
    }

    const trace = {
        endpoint: path,
        request_parameters: requestParams,
        response_code: responseCode,
        payload: responseCode === 'OK' ? [] : payload,
    };

    const stackTrace = SessionStorage.getItem('stack-trace') ?? '[]';
    const stackTraceAsObject = JSON.parse(stackTrace);

    // We limit the stack trace to 25 entries
    const newStackTrace = [trace, ...stackTraceAsObject.slice(0, 24)];
    SessionStorage.setItem('stack-trace', JSON.stringify(newStackTrace));
}
