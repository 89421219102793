import { Row, Col, Accordion } from 'react-bootstrap';
import { Reflect } from 'runtypes';
import { RuntypeError } from '../types/errors/runtype-error';

interface Props<R> {
    error: RuntypeError<R>;
}

const RuntypeDetailedError = <R,>({ error }: Props<R>) => {
    return (
        <div className="w-100 mt-3">
            <h3 className="pb-3">
                <b>{error.originalMessage}</b>
            </h3>

            <h6 className="py-1">
                <b>{error.failure.code}</b>
            </h6>

            {error.failure.details && (
                <>
                    <br />
                    <Accordion defaultActiveKey="compare" alwaysOpen>
                        <ExpectedVsActual error={error} />
                        <Details error={error} className="d-lg-none" />
                        <StackTrace error={error} />
                    </Accordion>
                </>
            )}
        </div>
    );
};

export default RuntypeDetailedError;

const Details = <R,>({ error, className }: Props<R> & { className?: string }) => (
    <Accordion.Item eventKey="details" style={{ backgroundColor: 'transparent' }} className={className ?? ''}>
        <Accordion.Header>
            <h4 className="text-danger">Details</h4>
        </Accordion.Header>
        <Accordion.Body>
            <code style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(error.failure.details, null, 2)}</code>
        </Accordion.Body>
    </Accordion.Item>
);

const ExpectedVsActual = <R,>({ error }: Props<R>) => (
    <Accordion.Item eventKey="compare" style={{ backgroundColor: 'transparent' }}>
        <Accordion.Header>
            <h4 className="text-danger">Expected / Actual</h4>
        </Accordion.Header>
        <Accordion.Body>
            <Row>
                <Col md={6} lg={4}>
                    <h4>
                        <b>Expected</b>
                    </h4>
                    <code style={{ whiteSpace: 'pre-wrap', fontSize: '15px' }}>
                        {stringifyRuntype(error.expected.reflect)}
                    </code>
                </Col>
                <Col md={6} lg={4}>
                    <h4>
                        <b>Actual</b>
                    </h4>
                    <code style={{ whiteSpace: 'pre-wrap', fontSize: '15px' }}>{error.formattedActual}</code>
                </Col>
                <Col md={6} lg={4} className="d-none d-lg-block">
                    <h4>
                        <b>Details</b>
                    </h4>
                    <code style={{ whiteSpace: 'pre-wrap', fontSize: '15px' }}>
                        {JSON.stringify(error.failure.details, null, 2)}
                    </code>
                </Col>
            </Row>
        </Accordion.Body>
    </Accordion.Item>
);

const StackTrace = <R,>({ error }: Props<R>) => {
    if (!error.stack) {
        return null;
    }

    return (
        <Accordion.Item eventKey="stackTrace" style={{ backgroundColor: 'transparent' }}>
            <Accordion.Header>
                <h4 className="text-danger">Stack Trace</h4>
            </Accordion.Header>
            <Accordion.Body>
                <code style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</code>
            </Accordion.Body>
        </Accordion.Item>
    );
};

function stringifyRuntype(r: Reflect, str: string = '', level: number = 0): string {
    const newLevel = level + 1;

    switch (r.tag) {
        case 'array':
            const arrayStringified = stringifyRuntype(r.element, str, newLevel);
            return `${arrayStringified}[]`;

        case 'record':
            const objectStringified = Object.entries(r.fields)
                .map(([key, value]) => `${' '.repeat(level)}${key}: ${stringifyRuntype(value, str, newLevel)}`)
                .join(';\n');

            return `{\n${objectStringified}\n${' '.repeat(level > 0 ? level - 1 : 0)}}`;

        case 'literal':
            const val = r.value;

            if (typeof val === 'undefined') {
                return 'undefined';
            }

            if (val === null) {
                return 'null';
            }

            return val.toString();

        case 'union':
            return r.alternatives.map((alt) => stringifyRuntype(alt, str, newLevel)).join(' | ');

        default:
            return r.tag as string;
    }
}
