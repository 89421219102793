import { Null, Number, Record, Static } from 'runtypes';
import { SystemLocale } from '../types/locale';

const JwtNormPeriod = Record({
    normPeriodsSNOCurrent: Number.Or(Null),
    normPeriodsSNOAny: Number.Or(Null),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
type JwtNormPeriod = Static<typeof JwtNormPeriod>;

const PERSON = 'person';
const NORM_PERIOD = 'normPeriod';

const getPerson = () => {
    const personStr = window.localStorage.getItem(PERSON);

    let person;
    try {
        if (personStr) {
            person = JSON.parse(personStr);
        } else {
            person = {};
        }
    } catch (_) {
        person = {};
    }

    return person;
};

export const updateLanguage = (language: string, locale: SystemLocale) => {
    const person = { ...getPerson(), language, locale };
    window.localStorage.setItem(PERSON, JSON.stringify(person));
};

export const setNormPeriod = (normPeriod: JwtNormPeriod) => {
    window.localStorage.setItem(NORM_PERIOD, JSON.stringify(normPeriod));
};

export const getNormPeriod = (): JwtNormPeriod | undefined => {
    const normPeriodPayload = window.localStorage.getItem(NORM_PERIOD);

    try {
        if (normPeriodPayload) {
            return JwtNormPeriod.check(JSON.parse(normPeriodPayload));
        }
    } catch (e) {
        console.warn(
            `\`normPeriod\` object cannot be read from local storage: ${
                (e as any).message
            }\n\nPayload\n${normPeriodPayload}`
        );
    }

    return undefined;
};

export const getLanguage = () => {
    return getPerson()?.language;
};
