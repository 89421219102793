import { getJwt } from '../jwt';
import { request, requestWithRuntype } from './request';
import { Settings } from './settings.type';

export async function getSettings() {
    const endpoint = 'settings-get-all-from-institution';
    return await requestWithRuntype(Settings, getJwt(), endpoint, {});
}

export async function saveSetting(settingsKeyName: string, value: any) {
    const endpoint = 'settings-value-create-or-update';
    return await request(getJwt(), endpoint, { settingsKeyName, value });
}
